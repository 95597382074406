<template>
  <div  class="popup-background" @click="doShowPopup">
    <div class="popup-container">
      <div v-if="alert" class="popup-box" @click.stop>
        <h3>{{ alert.text }}</h3>
        <h3 style="margin-bottom: 1em;"><mark>{{ queueText }}<br>{{ estimatedWait }}</mark></h3>
        <CustomButton v-if="alert.type=='Confirm'" @click="confirmAction" :buttonTitle="'CONFIRM'" :buttonIcon="'confirm'" :buttonColour="'red'"></CustomButton>
        <CustomButton @click="hideAlert" :buttonTitle="'CLOSE'" :buttonIcon="'back'" :buttonColour="'grey'"></CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import time from '@/mixins/time.js'
import CustomButton from "@/components/CustomButton.vue";
export default {
  name: "ALERTBOXM",
  mixins:[time],
  components: {
    CustomButton,
  },
  computed: {
    queueLength() {
      return this.$store.getters.getQueueLength
    },
    queueText() {
      return (this.queueLength + 1) + this.suffix(this.queueLength + 1) + " in the Queue"
    },
    estimatedWait() {
      if (this.queueLength == 0) {
        return "Less than 1 minute wait"
      } else if (this.queueLength < 3) {
        return "Less than " + (this.queueLength + 1) + " minutes wait"
      }
      return this.queueLength + " minutes wait"
    },
    startTime(){
      return this.$store.getters.getStartTime
    },
    alert(){
      return this.$store.getters.getAlert
    }
  },
  methods: {
    suffix(val) {
      let last = val % 10
      if (last == 1) {
        return "st"
      } else if (last == 2) {
        return "nd"
      } else if (last == 3) {
        return "rd"
      }
      return "th"
    },
    
    confirmAction(){
      if(this.alert.confirmAction){
        this.alert.confirmAction()
      }
    },
    hideAlert() {
      this.$store.commit("setShowAlert", false)
    }
  }  
};
</script>

<style scoped>
.popup-background {
  position: fixed;
  top: 0; bottom: 0; left: 0; right: 0;
  background-color: var(--transparent-charcoal);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.popup-container {
  position: fixed;
  top: 50px; bottom: 50px; left: 25px; right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-box {
  background-color: var(--charcoal);
  border: 2px solid var(--warm-sandstone);
  border-radius: 5px;
  padding: 2em;
  align-items: center;
  justify-content: center;
  z-index: 20;
}
mark {
  color: var(--grey);
}
</style>