<template>
  <HeaderBar></HeaderBar>
  <!-- SEARCH FIELDS -->
  <div class="searchPanel">
    <dropdown name="gName" :title="'Given Name'" @selected="setGivenName" :options="givenNames" placeholder="Select Given Name" style="z-index: 26;" maxItems="0" class="smallDD" :splitWords=true></dropdown>
    <dropdown name="fName" :title="'Family Name'" @selected="setFamilyName" :options="familyName" placeholder="Select Family Name" style="z-index: 24;"  maxItems="0" class="smallDD" :startsWith=true ></dropdown>
    <dropdown name="country" :title="'Country'" @selected="setCountry" :options="countries" placeholder="Select Country" style="z-index: 22;" maxItems="0" class="smallDD"></dropdown>
    <div style="display: flex;">
      <dropdown name="gender" :title="'Gender'" @selected="setGender" :options="genders" placeholder="Select Gender" style="z-index: 20; width: 60%; margin-right: 0px;" maxItems="0" class="smallDD" id="gender"></dropdown>
      <dropdown name="age" :title="'Age'" @selected="setAge" :options="ages" placeholder="Select Age" style="z-index: 19; width: 60%; margin-left: -55px;" maxItems="0" class="smallDD" id="age"></dropdown>
    </div>
    <dropdown name="trade" :title="'Trade'" @selected="setTrade" :options="tradesC" placeholder="Select Trade" style="z-index: 18;" maxItems="0" class="smallDD"></dropdown>
    <dropdown name="crime" :title="'Crime'" @selected="setCrime" :options="crimesC" placeholder="Select Crime" style="z-index: 16;" maxItems="0" class="smallDD"></dropdown>
    <dropdown name="ship" :title="'Ship'" @selected="setShip" :options="shipsC" placeholder="Select Ship" style="z-index: 14;" maxItems="0" class="smallDD"></dropdown>
    <dropdown name="year" :title="'Arrival Year'" @selected="setYear" :options="years" placeholder="Select Arrival Year" style="z-index: 12;" maxItems="0" class="smallDD"></dropdown>
    <!-- OVERLAY -->
    <div class="searchOverlay" :style="'visibility:'+overlayState+';'">
      <h2>{{ overlayText }}</h2>
      <div class="progress-container">
        <div class="progress-bar">
          <div class="progress-bar-filling" :style="progressAnimation"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- RESULTS -->
  <div v-if="conIndex > maxResults || !fieldSelected">
    <div style="display: flex; justify-content: center; margin-bottom: 0.75em;">
      <h3 style="color: var(--grey); margin-right: -0.25em;">Search Results:</h3>
      <h3 v-if="randomCons.length == 0">{{ possCount }} Match<span v-if="possCount != 1">es</span></h3>
      <h3 v-else>{{ randomCons.length }} Matches</h3>
    </div>
    <div v-if="possCount>=maxResults && randomCons.length==0">
      <div class="refine-search">
        <h2>Too many matches, refine your search</h2>
        <p style="margin-top: -0.5em;">or generate a RANDOM SELECTION below.</p>
      </div>
    </div>
    <!-- CONVICT ENTRIES -->
    <div style="margin-bottom: 2.5em;">
      <div v-for="(c, ci) in randomCons" :key="'r' + ci">
        <EntryCompact @click="showRandomConvict(ci)" :givenName="c.g+''" :familyName="c.f" :fmnCount="'0'" :viewCount="'0'"
                      :country="c.c" :age="c.a" :trade="c.t" :crime="c.cr" :ship="c.sh" :arrivalYear="c.ya"
                      :flag="flagIcon(c.c)" :iconTrade="personToIcon(c)" :iconCrime="''"></EntryCompact>
      </div>
      <div v-if="possCount<=maxResults && randomCons.length==0">
        <div v-for="(c,ci) in possCons" :key="'c'+ci">
          <EntryCompact @click="showConvict(ci)" :givenName="c.g" :familyName="c.f" :fmnCount="'0'" :viewCount="'0'"
                        :country="c.c" :age="c.a" :trade="c.t" :crime="c.cr" :ship="c.sh" :arrivalYear="c.ya"
                        :flag="flagIcon(c.c)" :iconTrade="personToIcon(c)" :iconCrime="''"></EntryCompact>
        </div>
      </div>
    </div>
    <!-- BUTTONS -->
    <CustomButton @click="randomConvict" :buttonTitle="'RANDOM SELECTION'" :buttonIcon="'random'" :buttonColour="'grey'"></CustomButton>
  </div>
</template>

<script>
import flags from '@/mixins/flags.js'
import Dropdown from '@/components/DropDown.vue'
import HeaderBar from "@/components/HeaderBar.vue";
import EntryCompact from "@/components/EntryCompact.vue";
import CustomButton from "@/components/CustomButton.vue";
import dataSet from '!raw-loader!./../assets/data/ConFinder.txt'
export default {
  name: "SearchView",
  mixins: [flags],
  components: {
    Dropdown,
    HeaderBar,
    EntryCompact,
    CustomButton,
  },
  data() {
    return {
      maxResults: 10,
      gName: null,
      fName: null,
      country: null,
      age: null,
      gender: null,
      ship: null,
      trade: null,
      year: null,
      crime: null,
      dataSet: null,
      allNames:[],
      givenNames:[],
      familyName:[],
      countries:[],
      ages:[],
      genders:[],
      ships:[],
      shipsC:[],
      years:[],
      trades:[],
      tradesC:[],
      crimes:[],
      crimesC:[],
      possCount: 0,
      possCons:[],
      randomCons:[],
      givenNamesAll:null,
      familyNamesAll:null,
      gendersAll:null,
      shipsAll:null,
      yearsAll:null,
      tradesAll:null,
      crimesAll:null,
      conIndex: 0,
      progressMax: 75000,
      optionsShown:false,
      overlayText:"Loading...",
      overlayState:"visible",
      isSorted:false,
    }
  },
  
  mounted() {
    console.log("MOUNTED")
    setTimeout(this.setUpData,100)
    this.overlayText="Accessing Database..."
  },
  created() {
    console.log("created")
  },
  computed: {
    reset(){
      return this.$store.getters.getReset
    },
    
    progressAnimation() {
      const width = (this.conIndex / this.progressMax) * 100;
      return {
        width: width + "%"
      };
    },
    isMaxReached() {
      return this.conIndex >= this.progressMax || this.possCount <= 10 ||!this.fieldSelected;
    },
    fieldSelected(){
      return this.gName || this.fName || this.country || this.age || this.gender || this.ship || this.trade || this.year || this.crime
    }
  },
  methods: {
    resetData(){
      if(this.country){
        console.log("clear country")
        this.setCountry(null)
      }
      if(this.age){
        this.setAge(null)
      }
      if(this.gender){
        this.setGender(null)
      }
      if(this.ship){
        this.setShip(null)
      }
      if(this.trade){
        this.setTrade(null)
      }
      if(this.year){
        this.setYear(null)
      }
      if(this.crime){
        this.setCrime(null)
      }
      if(this.gName){
        console.log("clear given name")
        this.setGivenName(null)
      }
      if(this.fName){
        this.setFamilyName(null)
      }
     
      //this. allNames=[]
  },
    showConvict(conIndex) {

      this.$store.commit("setPossibleConvicts",this.possCons)
      this.$store.commit("setPossibleIndex",conIndex)    
      this.$router.push("/convict-entry")
    },
    showRandomConvict(conIndex) {
      this.$store.commit("setPossibleConvicts",this.randomCons)
      this.$store.commit("setPossibleIndex",conIndex)
      console.log("CLEAR CONS")
      this.randomCons=[]
      this.$router.push("/convict-entry")
    },
    setUpData() {
      this.dataSet=dataSet.split('\n\r')
    this.allNames=[]
    //console.log(this.dataSet)
    let nameArray
    let nameObj
    let tmpS,tmpCr,tmpT
    let valid
    this.possCount=0
      for (var f in this.dataSet) {
          valid=true
          this.possCount++
          nameArray=this.dataSet[f].split("\t")
          tmpS=nameArray[3]
          if(tmpS=="M" || tmpS=="male" ) {
            tmpS="Male"
          } else if(tmpS=="F" ) {
            tmpS="Female"
          } else if(tmpS=="undefined") {
            tmpS="Unknown"
            valid=false
          }
          if(valid){
          tmpCr=nameArray[8]
          //if(Array.isArray(tmpCr)){
            tmpCr=tmpCr.split(",")[0]
          //}
          tmpT=nameArray[9]
         // if(Array.isArray(tmpT)){
          tmpT=tmpT.split(",")[0]
         // }
          if(nameArray[2]=="undefined"){
            nameArray[2]=""
          }
          nameObj={id:nameArray[0],g:nameArray[1],f:nameArray[2] ,s:tmpS,c:nameArray[4],a:(parseInt(nameArray[5],10) || "unknown"),sh:nameArray[6],ya:parseInt(nameArray[7],10) || "unknown",cr:tmpCr,t:tmpT }
          this.allNames.push( nameObj)
          }
       
      }
      console.log("ALLNAMES",this.possCount)
      this.$store.commit("setAllCons",this.allNames)
      this.overlayText="Sorting Given Names..."
      this.filterNames()
    },
    setGivenName(val){
      this.gName=val
      this.filterNames()
    },
    setFamilyName(val){
      this.fName=val
      this.filterNames()
    },
    setCountry(val){
      this.country=val
      this.filterNames()
    },
    setGender(val){
      this.gender=val
      this.filterNames()
    },
    setAge(val){
      this.age=val
      this.filterNames()
    },
    setTrade(val){
      this.trade=val
      this.filterNames()
    },
    setCrime(val){
      this.crime=val
      this.filterNames()
    },
    setShip(val){
      this.ship=val
      this.filterNames()
    },
    setYear(val){
      this.year=val
      this.filterNames()
    },
    randomConvict(){
      console.log("RANDOM",this.conIndex,this.allNames.length,this.possCons.length)
      if(this.conIndex==this.allNames.length){
        this.randomCons=[]
        for(var i=0;i<10 && i<this.possCons.length;i++){
          this.randomCons.push(this.possCons[Math.floor(Math.random()*this.possCons.length)])
        }
      //this.possCons=randomCon.slice()
      //this.possCount=this.possCons.length
      }else{
      //this.possCons=[]
      //this.possCount=0
      this.randomCons=[]
        for(var i=0;i<10;i++){
          this.randomCons.push(this.allNames[Math.floor(Math.random()*this.allNames.length)])
          //this.possCount++
        }
      }
    },
    filterNames() {
      this.givenNames=[]
      this.familyName=[]
      this.countries=[]
      this.genders=[]
      this.ships=[]
      this.shipsC=[]
      this.ages=[]
      this.trades=[]
      this.tradesC=[]
      this.crimes=[]
      this.crimesC=[]
      this.years=[]
      this.possCount=0
      this.conIndex=0
      this.possCons=[]
      this.randomCons=[]
      //this.processName()
      if(this.fieldSelected){
        this.overlayState="visible"
        setTimeout( this.processName,10)
        //this.processName()
        
      }else{
        this.overlayState="visible"
        setTimeout( this.setAllGNames,10)
        //this.setAllGNames()
      }
      
      return
    },
    waitForOptions(){
      if(!this.optionsShown){
       setTimeout(this.processName,100)
       this.overlayText="Filtering Data..."
      }else{
        setTimeout(this.waitForOptions,100)
        this.overlayText="Sorting Data Full..."
      }
    },
    
    setAllCrimes(){
      if(this.crimesAll){
       this.crimesC=this.crimesAll.concat([])
      }else{
        let length = this.allNames.length, result = [], seen = new Set();
        outerCR:  
        for (let index = 0; index < length; index++) {
          let value =this.allNames[index].cr;
          if (seen.has(value)) continue outerCR;
          seen.add(value);
          result.push(value);
        }
        this.crimesC =Array.from(seen).sort()
      }
        this.overlayText="Sorting Ships..."
        this.conIndex = 70000;
        setTimeout(this.setAllShips,10)
    },
    setAllGNames(){
      if(this.givenNamesAll){
       this.givenNames=this.givenNamesAll.concat([])
      }else{
      let length = this.allNames.length, result = [], seen = new Set();
     
        outerG:  

        for (let index = 0; index < length; index++) {
          let value =this.allNames[index].g;
          if (seen.has(value)) continue outerG;
          seen.add(value);
          result.push(value);

        }
        this.givenNames=Array.from(seen).sort()
      }
        this.overlayText="Sorting Family Names..."
        this.conIndex = 40000;
        setTimeout(this.setAllFNames,10)
    },
    setAllFNames(){
      if(this.familyNameAll){
       this.familyName=this.familyNameAll.concat([])
      }else{
      let length = this.allNames.length, result = [], seen = new Set();
      outerF:  
        for (let index = 0; index < length; index++) {
          let value =this.allNames[index].f;
          if (seen.has(value)) continue outerF;
          seen.add(value);
          result.push(value);
        }
        
        this.familyName=Array.from(seen).sort()
      }
      this.overlayText="Sorting Countries..."
        this.conIndex = 45000;
        setTimeout(this.setAllCountries,10)
    },
    setAllCountries(){
      if(this.countriesAll){
       this.countries=this.countriesAll.concat([])
      }else{
      let length = this.allNames.length, result = [], seen = new Set();
        outerC:  
        for (let index = 0; index < length; index++) {
          let value =this.allNames[index].c;
          if (seen.has(value)) continue outerC;
          seen.add(value);
          result.push(value);
        }
        this.countries =Array.from(seen).sort()
      }
        this.overlayText="Sorting Genders..."
        this.conIndex = 50000;
        setTimeout(this.setAllGenders,10)
    },
    setAllAges(){
      if(this.agesAll){
       this.ages=this.agesAll.concat([])
      }else{
      let length = this.allNames.length, result = [], seen = new Set();
        
        outerA:  
        for (let index = 0; index < length; index++) {
          let value =this.allNames[index].a;
          if (seen.has(value)) continue outerA;
          seen.add(value);
          result.push(value);
        }
        this.ages  =Array.from(seen).sort()
      }
        this.overlayText="Sorting Trades..."
        this.conIndex = 60000;
        setTimeout(this.setAllTrades,10)
    },
    setAllGenders(){
      if(this.gendersAll){
       this.genders=this.gendersAll.concat([])
      }else{
      let length = this.allNames.length, result = [], seen = new Set();
        outerS:  
        for (let index = 0; index < length; index++) {
          let value =this.allNames[index].s;
          if (seen.has(value)) continue outerS;
          seen.add(value);
          result.push(value);
        }
        this.genders =Array.from(seen).sort()
      }
        this.overlayText="Sorting Ages..."
        this.conIndex = 55000;
        setTimeout(this.setAllAges,10)
    },
    setAllShips(){
      if(this.shipsAll){
       this.shipsC=this.shipsAll.concat([])
      }else{
      let length = this.allNames.length, result = [], seen = new Set();
        
        outerSH:  
        for (let index = 0; index < length; index++) {
          let value =this.allNames[index].sh;
          if (seen.has(value)) continue outerSH;
          seen.add(value);
          result.push(value);
        }
        this.shipsC =Array.from(seen).sort()
      }
        this.overlayText="Sorting Arrival Years..."
        this.conIndex = 75000;
        setTimeout(this.setAllYears,10)
    },
    setAllTrades(){
      if(this.tradesAll){
       this.tradesC=this.tradesAll.concat([])
      }else{
      let length = this.allNames.length, result = [], seen = new Set();
        
        outerT:  
        for (let index = 0; index < length; index++) {
          let value =this.allNames[index].t;
          if (seen.has(value)) continue outerT;
          seen.add(value);
          result.push(value);
        }
        this.tradesC =Array.from(seen).sort()
      }
        this.overlayText="Sorting Crimes..."
        this.conIndex = 65000;
        setTimeout(this.setAllCrimes,10)
    },
    setAllYears(){
      if(this.yearsAll){
       this.years=this.yearsAll.concat([])
      }else{
      let length = this.allNames.length, result = [], seen = new Set();

        outerYA:  
        for (let index = 0; index < length; index++) {
          let value =this.allNames[index].ya;
          if (seen.has(value)) continue outerYA;
          seen.add(value);
          result.push(value);
        }
        this.years =Array.from(seen).sort()
      }
        //this.overlayText="Done"
        this.overlayState="hidden"
        this.possCount=length
    },

    processName(){
    
      let con
      let ai
      for(var c =0;c<1000 && this.conIndex<this.allNames.length;c++){
        con=this.allNames[this.conIndex]
        con.poss=true
        if(con.poss && this.gName && con.g.indexOf(this.gName)!=0){
          con.poss = false
        }
        if(con.poss && this.fName && this.fName!=con.f){
          con.poss = false
        }
        if(con.poss && this.country && this.country!=con.c){
          con.poss = false
        }
        if(con.poss && this.gender && this.gender!=con.s){
          con.poss = false
        }
        if(con.poss && this.age && this.age!=con.a){
          con.poss = false
        }
        if(con.poss && this.trade && this.trade!=con.t){
          con.poss = false
        }
        if(con.poss && this.crime && this.crime!=con.cr){
          con.poss = false
        }
        if(con.poss && this.ship && this.ship!=con.sh){
          con.poss = false
        }
        if(con.poss && this.year && this.year!=con.ya){
          con.poss = false
        }
        if(con.poss){
          this.possCount++
          if(this.possCons.length<=5000){
            this.possCons.push(con)
          }
          //single name test
          //con.g=con.g.split(" ")[0]
          if( !this.givenNames.includes(con.g.split(" ")[0]) ){
            this.givenNames.push(con.g)
          }
          if(!this.fName && !this.familyName.includes(con.f) ){
            this.familyName.push( con.f)
          }
          if(!this.country && !this.countries.includes(con.c) ){
            this.countries.push( con.c)
          }
          if(!this.gender && !this.genders.includes(con.s) ){
            this.genders.push( con.s)
          }
          if(!this.age && !this.ages.includes(con.a) ){
            this.ages.push( con.a)
          }
          //if(!this.trade && !this.trades.includes(con.t) ){
          //  this.trades.push( con.t)
          //}
          ai=this.trades.indexOf(con.t)
          if(!this.trade && ai ==-1){
            this.trades.push(con.t)
            this.tradesC.push({name:con.t,c:1})
          }else{
            if(this.tradesC[ai] && this.tradesC[ai].c){
              this.tradesC[ai].c++
            }else{
              this.tradesC.push({name:con.t,c:1})
            }
          }
          ai = this.crimes.indexOf(con.cr)
          if (!this.crime && ai == -1) {
            this.crimes.push(con.cr)
            this.crimesC.push({ name: con.cr, c: 1 })
          } else {
            if (this.crimesC[ai] && this.crimesC[ai].c) {
              this.crimesC[ai].c++
            } else {
              this.crimesC.push({ name: con.cr, c: 1 })
            }
          }
          ai = this.ships.indexOf(con.sh)
          if (!this.ship && ai == -1) {
            this.ships.push(con.sh)
            this.shipsC.push({ name: con.sh, c: 1 })
          } else {
            if (this.shipsC[ai] && this.shipsC[ai].c) {
              this.shipsC[ai].c++
            } else {
              this.shipsC.push({ name: con.sh, c: 1 })
            }
          }
          if (!this.year && !this.years.includes(con.ya)) {
            this.years.push(con.ya)
          }
        }
        this.conIndex++
      }
      //console.log(this.conIndex)
      if (this.optionsShown && false) {
        console.log("WAIT")
        this.waitForOptions()
      } else {
        if (this.conIndex < this.allNames.length - 1) {
          this.overlayText = "Filtering Data..."
          setTimeout(this.processName, 10)
        } else {
          this.overlayState = "hidden"
          // this.givenNames.sort()
          // this.familyName.sort()
          // console.log("Sort ages")
          // this.ages.sort()
          // this.trades.sort()
          // this.years.sort()
        }
      }
    },
    clearReset(){
      console.log("CLEAR RESET FROM SEARCH")
      this.$store.commit("setReset",false)
      //setTimeout(this.setUpData,100)
    }
    
  },
  watch:{
    reset(val){
      if(val){
        this.resetData()
        console.log("DO RESET FROM SEARCH")
        setTimeout(this.clearReset,500)
      }
     
    },
  }
};
</script>

<style>
.searchPanel {
  position: relative;
}
.searchOverlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  background-color: var(--transparent-charcoal);
  z-index: 2000;
}
.refine-search {
  color: var(--brick);
  padding: 1.5em 0 1.25em;
  margin: 0;
  margin-bottom: 1.1em;
  border-top: 1px solid var(--brick);
  border-bottom: 1px solid var(--brick);
}
.progress-container {
  display: flex;
  justify-content: center;
}
.progress-bar {
  width: 13em;
  height: 0.5em;
  background-color: var(--brick);
  border-radius: 1em;
  margin: 0.25em 0 0.5em;
}
.progress-bar-filling {
  width: 0%;
  height: 0.5em;
  background-color: var(--warm-sandstone);
  border-radius: 1em;
}
</style>