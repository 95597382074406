<template>
  <div class="countdown">
    <div class="timer">
      <h2>STARTING AT</h2>
      <h1>{{ startTime }}</h1>
      <h3 style="margin-top: -10px; text-transform: uppercase;">{{ queueLength }}{{ suffix(queueLength) }} in Queue</h3>
    </div>
  </div>
</template>

<script>
import { tSThisType } from '@babel/types';
export default {
  name: "EntryCircleCountdown",
  props: {
    givenName: String,
    familyName: String,
  },
  data() {
    return {
      d:null,
      t:null
    }
  },
  computed: {
    queueLength() {
      return this.$store.getters.getQueueLength
    },
    nextConIn() {
      return Math.max(0, this.$store.getters.getNextConIn)
    },
    panelTime() {
      return this.$store.getters.getPanelTime
    },
    conTimeSec(){
      return this.panelTime * (this.queueLength - 1) + this.nextConIn
    },
    conTime() {
      return this.timeText(this.conTimeSec)
    },
    startTime(){
      this.d = new Date();
      this.t=this.d.getHours()*60*60+this.d.getMinutes()*60+this.d.getSeconds()+this.conTimeSec
      return this.fixHours(Math.floor(this.t/60/60))+":"+this.twoDigit(Math.floor((this.t+30)/60)%60)
    }
  },
  methods: {
    suffix(val) {
      let last = val % 10
      if (last == 1) {
        return "st"
      } else if (last == 2) {
        return "nd"
      } else if (last == 3) {
        return "rd"
      }
      return "th"
    },
    timeText(time) {
      if (time <= 0) {
        return "00:00"
      }
      let min = Math.floor(time / 60)
      let sec = time - min * 60
      if (min < 10) {
        min = "0" + min
      }
      if (sec < 10) {
        sec = "0" + sec
      }
      return min + ":" + sec
    },
    fixHours(val){
      return val%12 ||12
    },
    twoDigit(val) {
      if (val < 10) {
        return "0" + val
      } else {
        return val
      }
    },
  }
};
</script>

<style scoped>
.countdown {
  background-color: var(--brick);
  width: 100%;
  height: 10em;
  box-sizing: border-box;
  align-items: center;
}
.timer h1 {
  font-size: 5em;
  margin: 0;
}
.timer h2 {
  margin-bottom: 0;
  color: var(--warm-sandstone);
}
</style>