<template>
  <div class="popup-background" @click="doShowPopup">
    <div class="popup-container">
      <div v-if="alert" class="popup-box" @click.stop>
        <img class="memorial-logo" src="../assets/forget_me_not.png">
        <h3>{{ rememberText }}</h3>
        <h3>{{ alert.text }}</h3>
        <CustomButton v-if="alert.type=='Confirm'" @click="confirmAction" :buttonTitle="'ADD FORGET-ME-NOT'" :buttonIcon="'forget-me-not'" :buttonColour="'blue'"></CustomButton>
        <CustomButton @click="hideAlert" :buttonTitle="'NOT THIS TIME'" :buttonIcon="'back'" :buttonColour="'grey'"></CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
export default {
  name: "ALERTBOXR",
  components: {
    CustomButton,
  },
  computed:{
    rememberText() {
      return "It's been said forget-me-nots are the spirits of dead loved ones. Whether it’s an unknown convict or an ancestor, when you place one of these delicate blue flowers you are telling them they’re in your thoughts."
    },
    alert(){
      return this.$store.getters.getAlert
    }
  },
  methods: {
    
    confirmAction(){
      if(this.alert.confirmAction){
        this.alert.confirmAction()
      }
    },
    hideAlert() {
      this.$store.commit("setShowAlert", false)
    }
  }  
};
</script>

<style scoped>
.popup-background {
  position: fixed;
  top: 0; bottom: 0; left: 0; right: 0;
  background-color: var(--transparent-charcoal);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.popup-container {
  position: fixed;
  top: 50px; bottom: 50px; left: 25px; right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-box {
  background-color: var(--charcoal);
  border: 2px solid var(--warm-sandstone);
  border-radius: 5px;
  padding: 2em;
  align-items: center;
  justify-content: center;
  z-index: 20;
  max-width: 26em;
}
.memorial-logo {
  width: clamp(8em, 40vw, 12em);
  margin: 1em 0;
}
</style>