<template>
  <div class="columns">

    <div style="display: flex;">
      <h3 class="column-left">Country</h3>
      <div style="width: 30px;"></div>
      <div class="column-right">
        <div v-if="country && country!='undefined'" class="entry-container">
          <h3>{{ country }}</h3>
          <div  :class="flag" class="flag-inline"></div>
        </div>
        <h3 v-else class="grey">Unknown</h3>
      </div>
    </div>

    <div style="display: flex;">
      <h3 class="column-left">Gender</h3>
      <div style="width: 30px;"></div>
      <div class="column-right">
        <h3 v-if="gender">{{ gender }}</h3>
        <h3 v-else class="grey">Unknown</h3>
      </div>
    </div>

    <div style="display: flex;">
      <h3 class="column-left">Age</h3>
      <div style="width: 30px;"></div>
      <div class="column-right">
        <h3 v-if="age">{{ age }}</h3>
        <h3 v-else class="grey">Unknown</h3>
      </div>
    </div>

    <div style="display: flex;">
      <h3 class="column-left">Trade</h3>
      <div style="width: 30px;"></div>
      <div class="column-right">
        <h3 v-if="trade">{{ trade }}
          <!-- <div v-if="iconTrade"</div> :class="'icon-small '+iconTrade"></div> -->
        </h3>
        <h3 v-else class="grey">Unknown</h3>
      </div>
    </div>

    <div style="display: flex;">
      <h3 class="column-left">Crime</h3>
      <div style="width: 30px;"></div>
      <div class="column-right">
        <h3 v-if="crime">{{ crime }} <mark>{{ iconCrime }}</mark></h3>
        <h3 v-else class="grey">Unknown</h3>
      </div>
    </div>

    <div style="display: flex;">
      <h3 class="column-left">Ship</h3>
      <div style="width: 30px;"></div>
      <div class="column-right">
        <h3 v-if="ship">{{ ship }}</h3>
        <h3 v-else class="grey">Unknown</h3>
      </div>
    </div>

    <div style="display: flex;">
      <h3 class="column-left">Arrival Year</h3>
      <div style="width: 30px;"></div>
      <div class="column-right">
        <h3 v-if="arrivalYear">{{ arrivalYear }}</h3>
        <h3 v-else class="grey">Unknown</h3>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "EntryDetails",
  props: {
    country: String,
    gender: String,
    age: { default: null },
    trade: String,
    crime: String,
    ship: String,
    arrivalYear: { default: null },
    flag: String,
    iconTrade: String,
    iconCrime: String,
  },
};
</script>

<style scoped>
.columns {
  display: flex;
  flex-direction: column;
  margin-top: 0.75em;
  margin-bottom: 1em;
}
.column-left {
  width: 369px;
  text-align: right;
  color: var(--brick);
  white-space: nowrap;
}
.column-right {
  width: 369px;
  text-align: left;
}
.entry-container {
  display: flex;
  position: relative;
  height: 1.5em;
}
.flag-inline {
  width: clamp(1.25em, 5vw, 1.6em);
  height: clamp(1.25em, 5vw, 1.6em);
  margin-top: -0.2em;
  margin-left: 0.75em;
  border-radius: 100%;
  background-color: var(--light-charcoal);
}
.icon-small {
  width: clamp(1.25em, 5vw, 1.6em);
  height: clamp(1.25em, 5vw, 1.6em);
  margin-top: 0.2em;
  display: inline-block;
  background-color: var(--light-charcoal);
  -webkit-mask-size: contain;
  -mask-size: contain;
  position: absolute;
}
h3 {
  margin: 0.25em 0;
  margin-top: 0;
}
.grey {
  color: var(--grey);
}
</style>