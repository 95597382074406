<template>
  <div style="display: flex; justify-content: center;">
    <div v-if="fmnCount && fmnCount!='undefined'" style="display: flex;">
      <img v-if="newDedication" class="count-image" src="../assets/icons/flower_remembered.svg">
      <img v-if="!newDedication" class="count-image" src="../assets/icons/flower_remembered.svg">
      <h3>{{ fmnCount }}</h3>
    </div>
    <div style="width: 0.5em;"></div>
    <div v-if="viewCount && viewCount!='undefined'" style="display: flex;">
      <img class="count-image" src="../assets/icons/views.svg">
      <h3>{{ viewCount }}</h3>
    </div>
    <div v-else style="display: flex;">
      <img class="count-image" src="../assets/icons/views_none.svg">
      <h3><mark>0</mark></h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "EntryStats",
  props: {
    fmnCount: Number,
    viewCount: Number,
    newDedication: Boolean
  },
};
</script>

<style scoped>
.count-image {
  height: clamp(1.5em, 6vw, 2.1em) ;
}
h3 {
  margin: 0.25em;
}
mark {
  color: var(--grey);
}
</style>