<template>
  <div class="dropdown-container">
    <label>
      <h3>{{ title }}</h3>
    </label>
    <div class="dropdown">
      <!-- Dropdown Input -->
      <input :ref="'Input'+name" :name="name" inputmode="search" @focus="showOptions()" @blur="exit()" @keyup="keyMonitor"
        v-model="searchFilter" :placeholder="placeholder" autocomplete="off" @compositionupdate="compositionUpdate($event)"/>
      <p v-if="!searchFilter" class="counter">{{ filteredOptions.length }}</p>
      <img v-if="searchFilter" src="../assets/icons/cancel.svg" class="remove" @click="clearField">
      <!-- Dropdown Menu -->
      <div class="dropdown-box" v-show="optionsShown">
        <div class="dropdown-item" @mousedown.prevent="selectOption(option)" @keyup.prevent="selectOption(option)" v-for="(option, index) in filteredOptions" :key="index">
          {{ option.name || option.id || option || '-' }} <span class="dropdown-counter">{{ option.c }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  template: 'Dropdown',
  props: {
    title: { type: String },
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name'
    },
    val: {
      type: String,
      required: false,
      default: 'val',
      note: 'Current Value'
    },
    options: {
      type: Array,
      required: true,
      default: [],
      note: 'Options of dropdown. An array of options with id and name',
    },
    counts: {
      type: Array,
      required: false,
      default: [],
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown'
    },
    maxItem: {
      type: Number,
      required: false,
      default: 0,
      note: 'Max items showing'
    },
    splitWords: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Split words on space'
    },
    startsWith:{
      type: Boolean,
      required: false,
      default: false,
      note: 'filters start with not just contains'
    },
  },
  data() {
    return {
      previousOpts: null,
      selected: null,
      optionsShown: false,
      searchFilter: '',
      disabled: false
    }
  },
  created() {
    //this.$emit('selected', this.selected);
    this.pHolder = this.placeholder
  },
  computed: {
    reset(){
      return this.$store.getters.getReset
    },
    filteredOptions() {
      let optionClean
      if (!this.options) {
        return []
      }
      const filtered = [];
     let regOption
     if(this.startsWith){
      if(this.searchFilter.name){
        regOption = new RegExp("^"+this.searchFilter.name, "i");
      }else{
         regOption =new RegExp("^"+this.searchFilter, "i");
      }
    }else{
      
      regOption = new RegExp(this.searchFilter.name || this.searchFilter, 'ig');
    }
     
      
      //const regOption = new RegExp(this.searchFilter.name || this.searchFilter, 'ig');
      //if(this.splitWords){
        //console.log("optionClean",this.searchFilter)
      //}
      for (var option of this.options) {
       
        if (option.name) {
          optionClean=option.name.toString()
          if(this.splitWords){
            optionClean=optionClean.split(" ")[0]
            
          }
          //console.log("optionClean",this.splitWords, optionClean)
          if (this.searchFilter.length < 1 || optionClean.match(regOption)) {
            if (filtered.length < this.maxItem || this.maxItem == 0) {
              filtered.push(option);
            }
          }
        } else {
          optionClean=option.toString()
          if(this.splitWords){
            
            optionClean=optionClean.split(" ")[0]
           // console.log("optionClean 2" , this.splitWords,optionClean)
          }
          //console.log("optionClean 2" , this.splitWords,optionClean)
          if (this.searchFilter.length < 1 || optionClean.match(regOption)) {
            if (filtered.length < this.maxItem || this.maxItem == 0  && !filtered.includes(optionClean)) filtered.push(optionClean);
          }
        }
      }
      if (document.getElementsByName(this.name) && document.getElementsByName(this.name)[0] && filtered) {
        if (filtered.length == 1) {
          //this.pHolder=filtered[0]
          document.getElementsByName(this.name)[0].placeholder = this.cleanOption(filtered[0].name || filtered[0])
        } else {
          document.getElementsByName(this.name)[0].placeholder = this.pHolder
        }
      }
      return filtered;
    }
  },
  methods: {
    compositionUpdate(event){
      //alert("UPDATE")
     
      this.searchFilter = event.data
     
    },
    cleanOption(option) {
      option = option.name || option.id || option || '-'
      if (!option || option == "u" || option == "undefined" ) {
        option = "Unknown"
      }
      return option
    },
    clearField() {
      this.selected = {}
      this.searchFilter = ''
      this.optionsShown = false;
      this.disabled = false
      this.pHolder = this.placeholder
      this.$emit('selected', null);
    },
    wipeField() {
      this.previousOpts= null
     
      this.selected = {}
      this.searchFilter = ''
      //this.$emit('selected', null);
      this.optionsShown = false;
      this.disabled = false
      this.pHolder = this.placeholder
    },
    selectOption(option) {
      //alert("SELECT "+option)
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.name || this.selected;
      this.$refs["Input"+this.name].value=this.searchFilter
      this.$emit('selected', this.selected.name || this.selected);
      document.activeElement?.blur()
    },
    setOption(option) {
      this.selected = option.name || option;
      this.optionsShown = false;
      // this.searchFilter = this.selected;
       this.disabled = true
      this.$emit('selected', this.selected);
    },
    showOptions() {
      if (!this.disabled) {
        this.options.sort()
        this.searchFilter = '';
        this.optionsShown = true;
        this.$parent.optionsShown=true

      } else {
        this.clearField()
        this.searchFilter = '';
        this.optionsShown = true;
        this.$parent.optionsShown=true
        // document.activeElement?.blur()
      }
    },
    exit() {
      this.optionsShown = false;
      this.$parent.optionsShown=false
    },
    // Selecting when pressing Enter
    keyMonitor: function (event) {
      //alert(event.which)
      if(event.which){
        //alert(this.searchFilter)
      }
      if (event.key === "Enter" && this.filteredOptions[0]) {
          if(this.filteredOptions.includes(this.searchFilter)){
            this.$emit('selected', this.searchFilter);
            document.activeElement?.blur()
          }else{
            this.searchFilter=this.filteredOptions[0]
            this.$emit('selected', this.filteredOptions[0]);
            document.activeElement?.blur()
          }
        //this.setOption(this.filteredOptions[0]);
      }
    }
  },
  watch: {
    reset(val){
      if(val){
        this.wipeField()
      }
    },
    filteredOptions(val) {
     
      if (val.length > 1) {
        this.previousOpts = val.slice()
      } else {
        if (val.length == 0) {
          this.previousOpts
        }
       }
    },
    searchFilterX(val) {
      
      if (this.filteredOptions.length === 0) {
        this.selected = {};
      } else {
        this.selected = this.filteredOptions[0];
        this.disabled = true
      }
    }
  }
};
</script>

<style lang="scss" scoped>
form {
  display: flex;
  position: relative;
}
label {
  width: clamp(100px, 25vw, 140px);
  min-width: clamp(100px, 25vw, 140px);
  height: 1.75em;
  margin-top: -12.5px;
  text-align: right;
  white-space: nowrap;
}
.smallDD#age {
  label {
    width: clamp(100px, 23vw, 118px);
    min-width: clamp(100px, 23vw, 118px);
  }
  .counter {
    color: transparent;
  }
}
.smallDD#gender {
  .counter {
    color: transparent;
  }
}
input {
  width: 100%;
  padding: 0.375em 10px 0.125em;
  border: none;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
  background-color: var(--cool-sandstone);
  font-family: "DIN Condensed Bold", sans-serif;
  font-size: clamp(1.25em, 5vw, 1.75em);
  z-index: 2;
}
input:hover {
  background-color: var(--white);
}
input:focus {
  background-color: var(--white);
}
::placeholder { // Chrome, Firefox, Opera, Safari 10.1+
  color: var(--light-grey);
  opacity: 1; // Firefox
}
:-ms-input-placeholder { // Internet Explorer 10-11
  color: var(--light-grey);
}
::-ms-input-placeholder { // Microsoft Edge
  color: var(--light-grey);
}
.dropdown-container {
  display: flex;
  margin-bottom: 5px;
  margin-right: 5px;
  z-index: 5;
  position: relative;
}
.dropdown {
  display: flex;
  width: 100%;
  min-width: 80px;
  box-sizing: border-box;
  font-family: "DIN Condensed Bold", sans-serif;
  position: relative;
  flex-grow: 1;
  z-index: 5;
}
.dropdown-box {
  width: 100%;
  max-height: 17.5em;
  position: absolute;
  top: 28px;
  left: 0px;
  box-shadow: 0 0 20px 5px var(--charcoal);
  border-radius: 0 0 5px 5px;
  border-top: 10px solid var(--light-grey);
  overflow: auto;
  z-index: 1;
}
.dropdown-item {
  font-size: clamp(1.25em, 5vw, 1.75em);
  line-height: 1.31em;
  padding: 0.4em 0.5em 0.2em;
  border-bottom: 1px solid var(--light-grey);
  text-align: left;
  display: block;
  color: var(--charcoal);
  background-color: var(--cool-sandstone);
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: var(--white);
  }
}
.dropdown-counter {
  position: absolute;
  right: 0px;
  color: var(--grey);
  margin-top: 0.125em;
  margin-right: 0.45em;
  font-size: 0.95em;
  z-index: 30;
}
.dropdown:hover .dropdowncontent {
  display: block;
}
.counter {
  position: absolute;
  right: 0px;
  color: var(--grey);
  margin: 0.6em 0.5em;
  font-size: clamp(0.9em, 5vw, 1.6em);
  line-height: 1em;
  z-index: 30;
}
.remove {
  width: clamp(1.6em, 6.5vw, 2.4em);
  position: absolute;
  right: 0px;
  margin: 0.25em;
  z-index: 2;
}
</style>