export default {

  data() {
    return {
     
    }
  },
  mounted() { 
    
  },
  computed: {},
  methods: {
    nextConIn() {
      return Math.max(0, this.$store.getters.getNextConIn)%60
    },
    currentTimeSeconds(){
      let now=new Date()
      return now.getHours()*60*60+now.getMinutes()*60+now.getSeconds()
    },
    getStartTime(){
      let now=new Date().getTime()
      this.startTime= Math.floor((this.$store.getters.getStartTime-now)/1000)
    },
    getStartTimeSeconds(){
      let now=new Date().getTime()
      return Math.floor((this.$store.getters.getStartTime-now)/1000) || 0
    },
    timeToText(secs){
      if(secs){
          let ampm="AM"
       
        let mins=Math.floor(secs/60)%60
      
        let hrs=Math.floor(secs/60/60)%24
        secs=secs%60
        //mins=mins-hrs*60
        if(hrs>12){
          ampm="PM"
        }
        //hrs=hrs%12 ||12
        return hrs+":"+this.twoDigit(mins)+ampm
      }
      return secs
    },
    twoDigit(val){
      if(val<10){
        return "0"+val
      }else{
        return val
      }
    },
  }
}
