import { createStore } from 'vuex'
import Vuex from 'vuex'
import dataSetCon from '!raw-loader!./../assets/data/ConFinder.txt'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAuth,signInWithEmailAndPassword } from 'firebase/auth';
import 'firebase/compat/firestore';
import { collection, Firestore, getCountFromServer, query, where } from "firebase/firestore";
import { getDatabase, ref,set,update,onValue} from "firebase/database";
import MaleImages from '!raw-loader!./../assets/data/MaleImages.txt'
import Female_Indian from '!raw-loader!./../assets/data/Female_Indian.txt'
import Female_Indonesian from '!raw-loader!./../assets/data/Female_Indonesian.txt'
import Female_SouthCentralAmerica from '!raw-loader!./../assets/data/Female_SouthCentralAmerica.txt'
import Female_WestIndiesCaribbean from '!raw-loader!./../assets/data/Female_WestIndiesCaribbean.txt'

import PlaceImages from '!raw-loader!./../assets/data/PlaceImages.txt'
import TasmaniaImages from '!raw-loader!./../assets/data/TasmaniaImages.txt'
export default createStore({
  state: {
    loggedIn: false,
    user:null,
    possibleConvicts:null,
    possibleIndex:null,
    showPopup: false,
    alert:null,
    showAlert:false,
    startCountdown: false,
    queued:null,
    webQueued:null,
    guideQueued:[],
    commands:null,
    convict:null,
    queuePos:0,
    time:0,
    startTime:0,
    panelTime:60,
    nextConIn:0,
    currentQueued:null,
    allCons:[],
    panelList:[],
    testing:false,
    FTB:false,
    currentWeather:null,
    currentTOD:null,
    currentEffects:null,
    lightMode:null,
    lightIndex:0,
    lightModes:null,
    projectorState:null,
    projectorIndex:0,
    projectorStates:null,
    audio:null,
    reload:null,
    intervention:"EMPTY",
    loginPath:null,
    showLogin:false,
    loginError:null,
    loginType:0,
    reset:false,
    
  },
  getters: {
    getReset(state){
      return state.reset
    },
    getLoggedIn(state){
      return state.loggedIn
    },
    getLoginType(state){
      return state.loginType
    },
    getShowLogin(state){
      return state.showLogin
    },
    getLoginError(state){
      return state.loginError
    },
    getLoginPath(state){
      return state.loginPath
    },
    getUser(state){
      return state.user
    },
    getAudio(state){
      return state.audio
    },
    getLightMode(state){
      return state.lightMode
    },
    getLightIndex(state){
      return state.lightIndex
    },
    getLightModes(state){
      return state.lightModes
    },
    getProjectorState(state){
      return state.projectorState
    },
    getProjectorIndex(state){
      return state.projectorIndex
    },
    getProjectorStates(state){
      return state.projectorStates
    },
    getCurrentWeather(state){
      return state.currentWeather
    },
    getIntervention(state){
      return state.intervention
    },
    getCurrentTOD(state){
      return state.currentTOD
    },
    getCurrentEffects(state){
      return state.currentEffects
    },
    getFTB(state){
      return state.FTB
    },
    getRELOAD(state){
      return state.reload
    },
    getMaleImages(){
      return MaleImages.split("\n")
    },
    getFemaleImages(){
      return FemaleImages.split("\n")
    },
    getFemaleImagesRace:(state)=> (race) => {
    
      if(race=="white"){
        return FemaleImages.split("\n")
      }else{
        switch(race){
          case "WestIndiesCaribbean":
            return Female_WestIndiesCaribbean.split("\n")
          case "Female_Indian":
              return Female_Indian.split("\n")
          case "Female_Indonesian":
            return Female_Indonesian.split("\n")
          case "SouthCentralAmerica":
            return Female_SouthCentralAmerica.split("\n")
          default:
            return FemaleImages.split("\n")
           
        }
      
       
        //return FemaleImages.split("\n")
      }
      
      
    },
    getTasmaniaImages(){
      return TasmaniaImages.split("\n")
    },
    getPlaceImages(){
      return PlaceImages.split("\n")
    },
    getTesting(state){
      return state.testing
    },
    getPanelList(state){
      return state.panelList
    },
    getCurrentQueued(state){
      return state.currentQueued
    },
    getAllCons(state){
      return state.allCons
    },
    getConById:(state)=> (id) => {
      for(var c in state.allCons){
        if(state.allCons[c].id==id){
          return state.allCons[c]
        }
      }
    },
    getTime(state){
      return state.time
    },
    getPanelTime(state){
      return state.panelTime
    },
    getNextConIn(state){
      return state.nextConIn
    },
    getLoading(state){
      return state.loading
    },
    getQueuePos(state){
      return state.queuePos
    },
    getConvict(state){
      return state.convict
    },
    getShowPopup(state) {
      return state.showPopup
    },
    getAlert(state) {
      return state.alert
    },
    getShowAlert(state) {
      return state.showAlert
    },
    getStartCountdown(state) {
      return state.startCountdown
    },
    getPossibleConvicts(state) {
      return state.possibleConvicts
    },
    getPossibleIndex(state) {
      return state.possibleIndex
    },
    getSelectedCon(state){
      if(state.possibleConvicts){
        return state.possibleConvicts[state.possibleIndex]
      }else{
        return null
      }
      
    },
    getWebQueue(state){
      return state.webQueued
    },
    getGuideQueue(state){
      return state.guideQueued
    },
    getStatus(state){
      return state.status
    },
    getCommands(state){
      return state.commands
    },
    getStartTime(state){
      return state.startTime
    },
    
    getQueueLength(state){
      if(state.webQueued){
        if(state.webQueued=="EMPTY"){
          return 0
        }
        return state.webQueued.length
      }
      return 0
    },
    getConvictById: (state) => (id) => {
      let cRef= firebase.firestore().collection("convicts")
      state.loading=true
      cRef.doc(id).get().then(querySnapshot => {
        if(!querySnapshot.empty){
          console.log("GOT",querySnapshot.data())
          state.loading=false
          return querySnapshot.data()
        }else{
         console.log("QUERY NO DATA")
         state.loading=false
        }
      }).catch(err => {
        console.log('Error logged', err);
        state.loading=false
      })
    },
    
  },
  mutations: {
    setReset(state,payload){
      state.reset=payload
    },
    setShowLogin(state,payload){
      state.showLogin=payload
    },
    setloginPath(state,payload){
      console.log("LOGIN FROM ",payload)
      state.loginPath=payload
    },
    setLoggedIn(state,payload){
      state.loggedIn=payload
    },
    setUser(state,payload){
      state.user=payload
      state.loggedIn=true
      console.log("SET loggedIn")

    },
    setLightMode(state,payload){
      state.lightMode=payload
    },
    setLightIndex(state,payload){
      state.lightIndex=payload
    },
    setLightModes(state,payload){
      state.lightModes=payload
    },
    setProjectorState(state,payload){
      state.projectorState=payload
    },
    setProjectorIndex(state,payload){
      console.log("setProjectorIndex",payload)
      state.projectorIndex=payload
    },
    setProjectorStates(state,payload){
      state.projectorStates=payload
    },
    setCurrentWeather(state,payload){
      state.currentWeather=payload
    },
    setCurrentTOD(state,payload){
      state.currentTOD=payload
    },
    setCurrentEffects(state,payload){
      state.currentEffects=payload
    },
    setFTB(state,payload){
      state.FTB=payload
    },
    setRELOAD(state,payload){
      state.reload=payload
    },
    setPanelList(state,payload){
      state.panelList=payload
    },
    setCurrentQueued(state,payload){
      state.currentQueued = payload
    },
    setAllCons(state,payload){
      state.allCons=payload
    },
    setNextConIn(state,payload){
      state.nextConIn=payload
    },
    setTime(state,payload){
      
      state.time=payload
    
    },
   
    setQueuePos(state,payload){
      state.queuePos=payload
    },
    setLoading(state,payload){
      state.loading=payload
    },
    setConvict(state,payload){
      state.convict=payload
    },
    setShowPopup(state, payload) {
      state.showPopup = payload
    },
    setShowAlert(state, payload) {
      state.showAlert = payload
    },
    setAudio(state,payload){
      state.audio=payload
    },
    setAlert(state, payload) {
      console.log("SET ALERT",payload)
      if(payload){
        state.alert=payload
        state.showAlert =true
      }else{
        state.showAlert =false
      }
    },
    setStartCountdown(state, payload) {
      state.startCountdown = payload
    },
    setPossibleConvicts(state, payload) {
      state.possibleConvicts = payload
    },
    setPossibleIndex(state, payload) {
      state.possibleIndex = payload
    },
    setWebQueued(state,payload){
      state.webQueued=payload
    },
    setIntervention(state,payload){
      state.intervention=payload
    },
    setGuideQueued(state,payload){
     state.guideQueued=payload
    },
    setCommands(state,payload){
      state.commands=payload
      if(payload.start){
        state.startTime=payload.start
      }
      if(payload.panelTime){
        state.panelTime=payload.panelTime
      }
      if(payload.FTB){
        state.sFTB=payload.FTB
      }
      if(payload.RELOAD){
        state.reload=payload.RELOAD
      }
    },
    setQueueInfo(state,data){
      
      if(data.panelList){
      
        state.panelList=data.panelList
      }
    },
    setLoginError(state,error){
      state.loginError=error
    },
    setLoginType(state,payload){
      state.loginType=payload
    },
    
  },
  actions: {
    setUpData({ commit ,getters}) {
      if(getters.getAllCons.length>1){
        return
      }
      let allNames=[]
      let dataSet=dataSetCon.split('\n\r')
      
      //console.log(dataSet)
      let nameArray
      let nameObj
      let tmpS,tmpCr,tmpT
      let valid
        for (var f in dataSet) {
            valid=true
            nameArray=dataSet[f].split("\t")
            tmpS=nameArray[3]
            if(tmpS=="M" || tmpS=="male" ) {
              tmpS="Male"
            } else if(tmpS=="F" ) {
              tmpS="Female"
            } else if(tmpS=="undefined") {
              tmpS="Unknown"
              valid=false
            }
            if(valid){
            tmpCr=nameArray[8]
            //if(Array.isArray(tmpCr)){
              tmpCr=tmpCr.split(",")[0]
            //}
            tmpT=nameArray[9]
           // if(Array.isArray(tmpT)){
            tmpT=tmpT.split(",")[0]
           // }
            if(nameArray[2]=="undefined"){
              nameArray[2]=""
            }
            nameObj={id:nameArray[0],g:nameArray[1],f:nameArray[2] ,s:tmpS,c:nameArray[4],a:(parseInt(nameArray[5],10) || "unknown"),sh:nameArray[6],ya:parseInt(nameArray[7],10) || "unknown",cr:tmpCr,t:tmpT }
            allNames.push( nameObj)
            }
        }
        commit("setAllCons",allNames)
      },
    logout({ commit }) {
      firebase.auth().signOut()
      console.log("LOG OUT")
      commit('setLoggedIn',false)
      commit('setUser',null)
      //commit('logout')
    },
    async logIn(context, { email, password }){
      const auth=getAuth()
      const response = await signInWithEmailAndPassword(auth, email, password).catch(error => {
        //  commit('setLoading', false)
        context.commit('setLoginError', "Invalid Password")
          console.log(error)
         
        })
      if (response) {
        console.log('SET_USER', response.user)
       // context.commit('setUser', response.user)
        this.dispatch("getUserInfo",response.user.uid)

      } else {
          console.log("login failed")

          
          //throw new Error('login failed')
      }
  },
  async autoSignIn(context, user){
    console.log("autoSignIn",user)
    //context.commit('setUser', user)
    this.dispatch("getUserInfo",user.uid)
},
  async getUserInfo({commit},payload){
    console.log("getUserInfo",payload)
    let cRef= firebase.firestore().collection("users")
    let user
    cRef.doc(payload).get().then((doc) => {
      if (doc.exists) {
        user=doc.data()
        user.uid=payload
          console.log("HAVE User data:", user);
          commit('setUser', user)
      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }
  }).catch((error) => {
      console.log("Error getting document:", error);
  });
      
  },
    getSettings({commit}){
      let cRef= firebase.firestore().collection("settings")
      let settings
      cRef.get().then(querySnapshot => {
        if (!querySnapshot.empty) {
          console.log("SETTINGS -- ", querySnapshot.docs)
          //var keyNames = Object.keys(querySnapshot.docs);
          querySnapshot.forEach(doc => {
            if (doc.data()) {
              settings = doc.data()
              if(Object.keys(settings)[0]=="LightModes"){
                console.log("LightModes -- ", settings.LightModes)
                commit('setLightModes',settings.LightModes)
              }else if(Object.keys(settings)[0]=="ProjectorFunctions"){
                console.log("ProjectorSettings -- ", settings.ProjectorFunctions)
                commit('setProjectorStates',settings.ProjectorFunctions)
              }
            }
          })        
        } else {
          console.log("QUERY NO DATA")
        }
      }).catch(err => {
        console.log('Error logged', err);
        
      })
    },
    
    async getConList({commit},list){
   
      let cRef= firebase.firestore().collection("convicts")
      let cons=[]
      for ( var i in list){
       // console.log("WAIT",list[i])
        let res=await getCon(list[i])
       // console.log("RESULT",res)
         if(res){
          res.listPos=i
          cons.push(res)
         }else{
          cons.push({GivenNames:list[i]})
         }
        
      }
      return cons
      function getCon(id) {
        //console.log("GET CON",id, cRef)
       
       // try{
         // console.log("GET CON2",cRef.doc(""+id))
       // }catch (error) {
          //console.error(error);
          //return null
         // console.log("GET CON2 ERROR",id)
       // }
        return new Promise(resolve => {
          cRef.doc(""+id).get().then(querySnapshot => {
           // console.log("querySnapshot",querySnapshot)
            if(!querySnapshot.empty){
             resolve( querySnapshot.data());
            }else{
             console.log("QUERY NO DATA")
              commit('setLoading',false)
            }
          }).catch(err => {
            console.log('Error logged', err);
            commit('setLoading',false)
          })
            
          
        });
      }
    },
    setLightMode({commit},payload){
      const db=getDatabase() 
    
      update(ref(db, 'Dynalight'),{"LightMode":payload})
    },
    setAudio({commit},payload){
      const db=getDatabase() 
     
      update(ref(db, 'Audio'),payload)
    },
    setProjectorState({commit},payload){
      const db=getDatabase() 
      //commit("setLightIndex",payload.index)
      update(ref(db, 'Dynalight'),{"Projector":payload})
      //update(ref(db, 'Dynalight'),{"LightMode":payload})
    },
    initListeners({commit}){
      console.log("Listening")
      const db=getDatabase()
      const queue= ref(db,"Queue")
      onValue(queue,(snapshot) => {
        const data = snapshot.val()
        commit("setWebQueued",data)
      })
      const intervention= ref(db,"Intervention")
      onValue(intervention,(snapshot) => {
        const data = snapshot.val()
        console.log("LISTEN",data)
        commit("setIntervention",data)
      })
      const guideQueue= ref(db,"GuideQueue")
      onValue(guideQueue,(snapshot) => {
        const data = snapshot.val()
        commit("setGuideQueued",data)
      })
      const queueInfo= ref(db,"QueueInfo")
      onValue(queueInfo,(snapshot) => {
       // console.log("QueueInfo",snapshot)
        const data = snapshot.val()
        commit("setQueueInfo",data)
      })
      
      const command= ref(db,"Commands")
      onValue(command,(snapshot) => {
        const data = snapshot.val()
        commit("setFTB",data.FTB)
        commit("setRELOAD",data.RELOAD)
        commit("setCommands",data)
      })
      const audio= ref(db,"Audio")
      onValue(audio,(snapshot) => {
        const data = snapshot.val()
        console.log("AUDIO FROM FB",data)
        commit("setAudio",data)
      })
      const dynalight= ref(db,"Dynalight")
      onValue(dynalight,(snapshot) => {
        const data = snapshot.val() 
        commit("setLightMode",data.LightMode)
        commit("setLightIndex",data.LightMode.index)
        commit("setProjectorState",data.Projector)
        console.log("data.Projector",data.Projector,data.Projector.index)
        commit("setProjectorIndex",data.Projector.index)
        //commit("setLightIndex",data.LightMode.index)
 
      })
      const environment= ref(db,"Environment")
      onValue(environment,(snapshot) => {
        const data = snapshot.val()
        commit("setCurrentWeather",data.weather)
        commit("setCurrentTOD",data.tod)
        commit("setCurrentEffects",data.effects)
      })
    },
    rememberConvict({commit},item){
      console.log("REMEMBER",item)
      let dedications=item.dedications || 0
      let cRef=firebase.firestore().collection("convicts")
      cRef.doc(item.id).set({dedications:dedications},{merge:true}).then(function(result){
        console.log("Convict Remembered",item.id)
      })
    },
    queueConvict({commit,getters},item){
      //let queue
      let queueObj={}
      let qLength=0
      console.log("Queue Convict",item.id,item,getters.getStatus)
      const db=getDatabase()
      //const queue= ref(db,"Queue")
      let nq=getters.getWebQueue
      nq.push(item.id)
      console.log(nq)
      set(ref(db, 'Queue'),nq);
      commit("setCurrentQueued",item)
    },
    queueGuideConvict({commit,getters},item){
      let nq=getters.getGuideQueue || []
      console.log("PUSH CONVICT")
      nq.push(item)
      const db=getDatabase()
      set(ref(db, 'GuideQueue'),nq);

    },
    dequeueGuideConvict({commit,getters},item){
      const db=getDatabase()
      let nq=getters.getGuideQueue
      let index=0
      for(var n in nq){
        if(nq[n].Name==item.Name){
          index=n
        }
      }
      nq.splice(index,1)
      
      set(ref(db, 'GuideQueue'),nq);

    },
    queueIntervention({commit,getters},item){
      const db=getDatabase()
      
      set(ref(db, 'Intervention'),item);
    },
    setEnvironment({commit,getters},item){
      const db=getDatabase()
      console.log("Set Environment",item)
      set(ref(db, 'Environment'),item);
    },
    tickCommand({commit},val){
      //const db=getDatabase() 
     // update(ref(db, 'Commands'),{"test1":val})
    },
    fadeToBlack({commit},val){
      const db=getDatabase() 
      commit("setFTB",val)
     update(ref(db, 'Commands'),{"FTB":val})
    },
    reload({commit},val){
      const db=getDatabase() 
      commit("setRELOAD",val)
     update(ref(db, 'Commands'),{"RELOAD":val})
    },
    Restart({commit},val){
      const db=getDatabase() 
      
      update(ref(db, 'Commands'),{"RESTART":val})
    },
    async getConvictById({commit},id){
      let cRef= firebase.firestore().collection("convicts")
      console.log("GETTING",id)
      cRef.doc(id).get().then(querySnapshot => {
        if(!querySnapshot.empty){
          console.log("GOT",querySnapshot.data())
          commit("setConvict",querySnapshot.data())
          return querySnapshot.data()
        }else{
         console.log("QUERY NO DATA")
          commit('setLoading',false)
        }
      }).catch(err => {
        console.log('Error logged', err);
        commit('setLoading',false)
      })
    },
  },
  modules: {
  }
})