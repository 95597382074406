<template>
  <HeaderBar></HeaderBar>
  
  <div v-if="queueLength == 0"></div>
  <div >
    
   
    <div v-for="(w, wi) in reverseWebQueue" :key="'w' + wi">
      <QueueCompact v-if="w != 'EMPTY' && getCon(w)"
                    :isActive="isCurrent(w)" :givenName=getCon(w).g :familyName=getCon(w).f
                    :queueStatus="'QUEUED'"
                    :queueSub="queueTime(reverseWebQueue.length-wi)"
                    :flag="'fib ' + flagIcon(getCon(w).c) + ' fis'" :iconTrade="personToIcon(w)" :id=getCon(w).id :queuePos="(reverseWebQueue.length-wi)">
      </QueueCompact>
    </div>
    <QueueCompact v-if="queueHead != 'EMPTY' && getCon(queueHead)"
                    :isActive="isCurrent(queueHead)" :givenName=getCon(queueHead).g :familyName=getCon(queueHead).f
                    :queueStatus="'Loading'"
                    :flag="'fib ' + flagIcon(getCon(queueHead).c) + ' fis'" :iconTrade="personToIcon(queueHead)" :id=getCon(queueHead).id >
      </QueueCompact>
  </div>
  <div v-if="playingCons && playingCons.length > 0" style="margin-bottom: 2.5em;">
    <div v-for="(w, pi) in playingCons" :key="'p' + pi">
     
      <QueueCompact v-if="w != 'EMPTY'" :queueStatus="'SCREEN ' + (pi+1)" :queueSub="panelNames[pi]"
                    :isActive="''" :givenName=w.GivenNames :familyName=w.FamilyName
                    :flag="'fib ' + flagIcon(w.NP_Country) + ' fis'"
                    :iconTrade="personToIcon(w)"
                    :id=w.id
                    style="border-top: 1px solid var(--warm-sandstone); border-bottom: 1px solid var(--warm-sandstone); background-color: var(--blue);">
      </QueueCompact>
    </div>
  </div>

  <CustomButton v-if="!singleUser" @click=freeConvict() :buttonTitle="'FREE A CONVICT'" :buttonIcon="'search'" :buttonColour="'red'"></CustomButton>

  <CustomButton v-if="singleUser" @click=goBack() :buttonTitle="'GO BACK'" :buttonIcon="'back'" :buttonColour="'grey'"></CustomButton>
</template>

<script>
import flags from '@/mixins/flags.js'
import time from '@/mixins/time.js'
import HeaderBar from "@/components/HeaderBar.vue";
import QueueCompact from "@/components/QueueCompact.vue";
import CustomButton from "@/components/CustomButton.vue";
import { QueryCompositeFilterConstraint } from 'firebase/firestore';
export default {
  name: "QueueListView",
  mixins: [flags,time],
  components: {
    HeaderBar,
    QueueCompact,
    CustomButton
  },
  data() {
    return {
      playingCons: null,
      panelNames: ["THE RECORDS", "THE CRIME", "THE VOYAGE", "THE NEW WORLD"],
      queueHead:null,
      newHead:null
    }
  },
  mounted() {
    
    if (this.panelList) {
      this.adjustPanelList(this.panelList)
      this.fullQueue()
      
    }
    
    this.$store.dispatch("setUpData")
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    singleUser(){
      if(this.user){
       return false
      }
      return true
     
    },
    panelList(){
      return this.$store.getters.getPanelList
    },
    currentQueued(){
      return this.$store.getters.getCurrentQueued
    },
    queueLength(){
      return this.$store.getters.getQueueLength
    },
    webQueue(){
      return this.$store.getters.getWebQueue
    },
    reverseWebQueue(){
      if(Array.isArray(this.webQueue)){
        return this.webQueue.reverse()
      }else{
        return this.webQueue
      }
    }
  },
  methods: {
    queueTime(queuePos){
      if(queuePos){
        return this.timeToText(this.currentTimeSeconds()+(queuePos-1)*60+this.nextConIn())
        //return this.timeToText((this.currentTimeSeconds()+queuePos*60)*1000)
      }
    },
    goBack(){
      this.$router.back()
    },
    freeConvict(){
      this.$router.push("/search")
      this.$store.commit("setReset",true)
    },
    isCurrent(id){
      if(this.currentQueued && this.currentQueued.id==id){
        return " active"
      }else{
        return ""
      }
    },
    getCon(id){
      let con=this.$store.getters.getConById(id)
      return con
    },
    async fullQueue(){
      // console.log("getConList",this.playingCons,this.panelList.slice())
      // this.playingCons=this.panelList.slice()
      this.playingCons= await this.$store.dispatch("getConList",this.panelList.slice())
      if(Array.isArray(this.playingCons)){
        this.playingCons=this.playingCons
      }
    },
    setHead(val){
      this.panelList[0]=val
      if(this.panelList[0]==this.queueHead){
       
        this.queueHead=null
      }
      this.fullQueue()
    },
    adjustPanelList(val,old){
      let doUpdate=true
      for(var v in val){
        if(v==0){
          this.newHead=val[0]
            if(old && old[0]){
              val[0]=old[0]
            }
        }
        if(v>0 && val[v]==val[v-1]){
          val[v-1]=null
          if(v==1){
            //doUpdate=false
            setTimeout(this.setHead, 2000,this.newHead)
            
          }
        }
      }
      if(val[0]!=this.newHead){
        this.queueHead=this.newHead
      }else{
        this.queueHead=null
      }
      if(doUpdate){
        this.fullQueue()
      }
      
    }
    
  },
  watch:{
    webQueue(val){
      console.log("HAS WEB QUEUE",this.queueLength,val)
    },
    panelList(val,old){
      this.adjustPanelList(val,old)
    }
  }
};
</script>


<style scoped>



</style>