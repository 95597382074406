<template>
  <HeaderBar></HeaderBar>
  <EntryCircle :givenName="'AARON'" :familyName="'SOLOMON'" :flag="'fib fi-gb fis'" :iconTrade="'anvil'" :iconCrime="''"></EntryCircle>
  <h3>Now Playing</h3>
  <h2>Follow Your Convict</h2>
  <CustomButton @click="doShowPopup" :buttonTitle="'REMEMBER ME'" :buttonIcon="'forget-me-not'" :buttonColour="'blue'"></CustomButton>
</template>

<script>
import HeaderBar from "@/components/HeaderBar.vue";
import EntryCircle from "@/components/EntryCircle.vue";
import CustomButton from "@/components/CustomButton.vue";
export default {
  name: "NowPlayingView",
  components: {
    HeaderBar,
    EntryCircle,
    CustomButton,
  },
  computed: {
    showPopup() {
      return this.$store.getters.getShowPopup
    }
  },
  methods: {
    doShowPopup() {
      this.$store.commit("setShowPopup", !this.showPopup)
    }
  },
};
</script>