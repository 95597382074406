<template>
  <div class="home" @click="enterWebsite">
    <img class="memorial-logo" src="../assets/forget_me_not.png">
    <div>
      <img class="unshackled" src="../assets/unshackled.png">
      <h1 class="convict-memorial"><mark>CONVICT</mark><br> MEMORIAL</h1>
      <!--<h2>AN <mark>UN</mark>SHACKLED <mark>EXHIBITION</mark></h2>-->
    </div>
    <h1 class="paragraph">OVER <mark>75,000 STORIES</mark><br> OF CRUELTY, REBELLION<br> AND SURVIVAL</h1>
    <router-link to="/search" style="margin-bottom: 1.5em">
      <CustomButton :buttonTitle="'FREE A CONVICT'" :buttonIcon="'search'" :buttonColour="'red'"></CustomButton>
    </router-link>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
export default {
  name: "HomeView",
  components: {
    CustomButton,
  },
  methods: {
    enterWebsite() {
      this.$router.push('/search');
    },
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 95vh;
}
.memorial-logo {
  width: clamp(8em, 40vw, 12em);
  margin-top: 2em;
  margin-bottom: 0.5em;
}
.unshackled {
  height: clamp(1.75em, 7.5vw, 2.5em);
}
.convict-memorial {
  font-size: clamp(4.5em, 17.5vw, 6em);
  margin-top: 0.1em;
  line-height: 0.85em;
}
.paragraph {
  font-size: clamp(2em, 8vw, 2.75em);
  line-height: 0.95em;
}
h1 {
  margin: 0;
  white-space: nowrap;
}
h2 {
  margin-top: -0.25em;
}
</style>