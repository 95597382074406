<template>
  <div class="popup-background" @click="doShowPopup">
    <div class="popup-container">
      <div class="popup-box" @click.stop>
        <div class="popup-tabs">
          <div @click="selectTab('qr')" :class="{ 'active': selectedTab === 'qr' }"><h2>QR</h2></div>
          <div @click="selectTab('help')" :class="{ 'active': selectedTab === 'help' }"><h2>HELP</h2></div>
          <!-- <div @click="selectTab('faq')" :class="{ 'active': selectedTab === 'faq' }"><h2>FAQ</h2></div> -->
          <div @click="selectTab('about')" :class="{ 'active': selectedTab === 'about' }"><h2>ABOUT</h2></div>
        </div>
        <div class="gradient" style="background-image: linear-gradient(#191919 0%, #19191900 100%); top: 3.2em;"></div>
        <div class="gradient"></div>
        <div class="popup-content">
          <!-- QR CODE -->
          <div v-if="selectedTab === 'qr'">
            <div class="image-box"><img style="max-width: 33em;" src="../assets/qr_code.png"></div>
          </div>
          <!-- HELP TAB -->
          <div v-if="selectedTab === 'help'">
            <h3>Use this app to display a convict of your choosing on the <span style="color: var(--brick);">CONVICT MEMORIAL!</span></h3>
            <h3>To search for a convict, enter information into any of the fields. Keep adding information to narrow down your results.</h3>
            <div class="image-box"><img src="../assets/help_screen_1.jpg"></div>
            <h3><span style="color: var(--grey);">You can get a randomised set of convicts by pressing the RANDOM SELECTION button.</span> When there are 10 or fewer results, a list of convicts will appear. Tap on any convict to show their details.</h3>
            <div class="image-box"><img src="../assets/help_screen_2.jpg"></div>
            <h3>When viewing a convict, press the <span style="color: var(--brick);">ADD TO QUEUE</span> button and confirm your choice. Your convict will then begin playing on the column at the time shown. <span style="color: var(--grey);">You can only queue one convict at a time.</span></h3>
            <div class="image-box"><img src="../assets/help_screen_3.jpg"></div>
            <h3>You may also choose to make a dedication to your convict by pressing the <span style="color: var(--light-blue);">REMEMBER ME</span> button.</h3>
            <h3>At your scheduled time, your convict will appear on the NAMES SCREEN. Follow your convict's name as it moves left around the column. Once your convict has finished playing you may choose another.</h3>
            <div class="image-box"><img src="../assets/help_screen_4.jpg"></div>
          </div>
          <!-- FAQ TAB -->
          <div v-else-if="selectedTab === 'faq'">
            <h3 class="question">WHAT IS THE MEMORIAL?</h3>
            <p></p>
            <h3 class="question">WHERE DOES OUR DATA COME FROM?</h3>
            <p></p>
            <h3 class="question">WHY CAN'T I FIND MY CONVICT?</h3>
            <p></p>
            <h3 class="question">WHERE DO THE IMAGES OF THE CONVICTS COME FROM?</h3>
            <p></p>
          </div>
          <!-- ABOUT TAB -->
          <div v-else-if="selectedTab === 'about'">
            <h3>The Unshackled CONVICT MEMORIAL brings together twenty-two different data sets from the UNESCO-listed Tasmanian convict records. The memorial has been established to commemorate the lives and deaths of over 75,000 men, women and children who were exiled to Van Diemen’s Land between 1803 and 1854.</h3>
            <h3>The former courtroom which houses this memorial was originally part of a prison complex occupying two city blocks. The walls of this building have witnessed more than its fair share of misery, tragedy and brutality. As you follow a convict around the memorial, spare a thought for this unfree immigrant... and perhaps dedicate a digital forget-me-not to their memory.</h3>
            <h3>Developed with funding and support from the Tasmanian Government.</h3>
            <div style="display: flex;">
              <div class="image-box"><img style="max-width: 28em;" src="../assets/logos.png"></div>
            </div>
          </div>

        </div>
        
        <div style="margin: 0 0 1em;">
          <CustomButton @click="doShowPopup" :buttonTitle="'CLOSE'" :buttonIcon="'back'" :buttonColour="'grey'"></CustomButton>
        </div>
        <div v-if="selectedTab === 'about'" class="version">VERSION 1.07</div>
      </div>
    </div>
  </div>
</template>


<script>
import CustomButton from "@/components/CustomButton.vue";
export default {
  name: "PopupBox",
  components: {
    CustomButton,
  },
  data() {
    return {
      selectedTab: 'help',
    };
  },
  methods: {
    doShowPopup() {
      this.$store.commit("setShowPopup", false);
    },
    selectTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>

<style scoped>
.popup-background {
  position: fixed;
  top: 0; bottom: 0; left: 0; right: 0;
  background-color: var(--charcoal);
  z-index: 9000;
}
.popup-container {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 25px; bottom: 25px; left: 25px; right: 25px;
}
.popup-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 768px;
  background-color: var(--charcoal);
  outline: 2px solid var(--warm-sandstone);
  border-radius: 5px;
  z-index: 9100;
  position: relative;
}
.popup-tabs {
  display: flex;
  width: 100%;
  height: 3.25em;
  top: 0;
  gap: 3px;
  padding-bottom: 0.25em;
}
.popup-tabs div {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--warm-sandstone);
  background-color: var(--grey);
  top: 0;
  flex: 1;
  cursor: pointer;
}
.popup-tabs div.active {
  color: var(--charcoal);
  background-color: var(--warm-sandstone);
}
.popup-content {
  width: 100%;
  flex: 1;
  padding: 1em 2em 0;
  overflow-y: auto;
  text-align: left;
}
.gradient {
  width: 100%;
  height: 2em;
  position: absolute;
  background-image: linear-gradient(#19191900 0%, #191919 100%);
  bottom: 4.7em;
}
.version{
  position:absolute;
  bottom:0px;
  right:10px;
  opacity:0.3;
  font-size: 0.8rem;
}
@media screen and (min-width: 500px) {
  .gradient {
    bottom: 5em;
  }
}
@media screen and (min-width: 600px) {
  .gradient {
    bottom: 5.4em;
  }
}
h2 {
  margin-top: 0.75em;
}
h3, p, li {
  margin: 0.5em 0;
  line-height: 1.1;
}
li {
  margin-left: 0.75em;
}
.answer {
  margin-bottom: 1em;
}
.image-box {
  width: 100%;
  display: flex;
  justify-content: center;
}
img {
  width: 100%;
  max-width: 28em;
  z-index: -1;
}
</style>