<template>
  <div class="popup-background" >
    <div class="popup-container">
      <div class="popup-box" >
        <h3 @click="nextUser">{{ users[loginIndex].name }} PASSWORD</h3>
        <input  type="password" @focus="doFocus" @blur="exit()" 
        v-model="pword"  autocomplete="off"/>
        <CustomButton  @click="confirmAction" :buttonTitle="'LOGIN'" :buttonIcon="'confirm'" :buttonColour="'red'"></CustomButton>
        <div>{{loginError}}</div> 
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
import { toHandlers } from "vue";
export default {
  name: "LOGINBOX",
  components: {
    CustomButton,
  },
  data() {
    return {
      pword:null,
      users:[{name:"GUIDE",email:"guide@formula7.com"},
             {name:"LIGHTING",email:"lighting@formula7.com"},
             {name:"ADMIN",email:"roar@formula7.com"},
             {name:"iPad",email:"ipad@formula7.com"}
            ],
    }
  },
  computed:{
    loginIndex(){
      return this.$store.getters.getLoginType || 0
    },
    loginError(){
      return this.$store.getters.getLoginError
    }
  },
  methods: {
    nextUser(){
      this.$store.commit("setLoginType",(this.loginIndex+1)%this.users.length)
    },
    doFocus(){
      console.log("FOCUS")
      this.$store.commit("setLoginError",null)
    },
    confirmAction(){
      this.$store.dispatch("logIn",{email:this.users[this.loginIndex].email,password:this.pword})
    },
    hideAlert() {
      //this.$store.commit("setShowAlert", false)
    },
    exit(){

    }
  }  
};
</script>

<style scoped>
.popup-background {
  position: fixed;
  top: 0; bottom: 0; left: 0; right: 0;
  background-color: var(--transparent-charcoal);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.popup-container {
  position: fixed;
  top: 50px; bottom: 50px; left: 25px; right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-box {
  background-color: var(--charcoal);
  border-radius: 5px;
  padding: 2em;
  align-items: center;
  justify-content: center;
  z-index: 20;
}
input {
  width: 100%;
  padding: 0.375em 10px 0.125em;
  border: none;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
  background-color: var(--cool-sandstone);
  font-family: "DIN Condensed Bold", sans-serif;
  font-size: 1.25em;
  z-index: 2;
}
</style>