<template>
  <div :class="'custom-button '+active">
    <img class="icon" :class="buttonIcon">
    <div class="button-textbox" :class="buttonColour">
      <h2>{{ buttonTitle }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomButton",
  props: {
    buttonTitle: String,
    buttonIcon: String,
    buttonColour: String,
    disabled: { type: Boolean, default: false }
  },
  computed: {
    active() {
      if (this.disabled) {
        return "disabled"
      }
    }
  }
};
</script>

<style scoped>
.custom-button {
  width: 15em;
  height: 2.5em;
  margin: 0.6em auto;
  border-radius: 3px;
  color: var(--warm-sandstone);
  background: linear-gradient(to right, var(--light-charcoal) 25%, var(--charcoal) 75%);
  cursor: pointer;
  display: flex;
  transition: 0.1s;
  position: relative;
}
.custom-button.disabled {
  opacity: 0.35;
  pointer-events: none;
}
.button-textbox {
  width: 12em;
  border-radius: 3px;
  color: var(--warm-sandstone);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
}
.custom-button:hover {
  color: var(--charcoal);
  .button-textbox {
    color: var(--charcoal);
    background-color: var(--warm-sandstone);
  }
}
h2 {
  margin: 0;
  margin-top: 0.3em;
  white-space: nowrap;
}
.red {
  background-color: var(--brick);
  mask: url(../assets/texture_1.png) 0 0/192px 40px;
}
.grey {
  background-color: var(--grey);
  mask: url(../assets/texture_2.png) 0 0/192px 40px;
}
.blue {
  background-color: var(--blue);
  mask: url(../assets/texture_3.png) 0 0/192px 40px;
}
@media screen and (min-width: 500px) {
  .icon {
    transform: scale(1.125);
    margin: 0.45em 0.75em;
  }
  .custom-button {
    width: 18em;
    height: 2.825em;
  }
  .button-textbox {
    width: 14.5em;
  }
  .red {
    background-color: var(--brick);
    mask: url(../assets/texture_1.png) 0 0/232px 48px;
  }
  .grey {
    background-color: var(--grey);
    mask: url(../assets/texture_2.png) 0 0/232px 48px;
  }
  .blue {
    background-color: var(--blue);
    mask: url(../assets/texture_3.png) 0 0/232px 48px;
  }
}
@media screen and (min-width: 600px) {
  .icon {
    transform: scale(1.25);
    margin: 0.625em 1em;
  }
  .custom-button {
    width: 21em;
    height: 3.25em;
  }
  .button-textbox {
    width: 17em;
  }
  .red {
    background-color: var(--brick);
    mask: url(../assets/texture_1.png) 0 0/272px 55px;
  }
  .grey {
    background-color: var(--grey);
    mask: url(../assets/texture_2.png) 0 0/272px 55px;
  }
  .blue {
    background-color: var(--blue);
    mask: url(../assets/texture_3.png) 0 0/272px 55px;
  }
}

</style>