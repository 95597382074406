<template>
  <div class="compact">
    <div class="compact-circle">
      <div :class="'icon-circle ' + iconTrade"></div>
      <div v-if="flag && flag!='fib fis' && flag.indexOf('undefined')==-1" :class="'flag-circle ' + flag"></div>
      <div v-else></div>
    </div>
    <div class="compact-text">
      <div class="compact-title">
        <h3>{{ cleanName(givenName) }} {{ cleanName(familyName) }}</h3>
        <!--
        <div style="display: flex;">
          <div v-if="fmnCount" style="display: flex;">z
            <img style="height: 1.5em; margin-top: -3px;" src="../assets/icons/flower.svg">
            <h4>{{ fmnCount }}</h4>
          </div>
          <div style="width: 0.25em;"></div>
          <div v-if="viewCount" style="display: flex;">
            <img style="height: 1.5em; margin-top: -3px;" src="../assets/icons/views.svg">
            <h4>{{ viewCount }}</h4>
          </div>
          <div v-else style="display: flex;">
            <img style="height: 1.5em; margin-top: -3px;" src="../assets/icons/views_none.svg">
            <h4><mark>0</mark></h4>
          </div>
        </div>
        -->
      </div>
      <p>
        <span v-if="country && country != 'undefined'">{{ country }}, </span>
        <span v-if="age && age != 'unknown'">Age {{ age }}, </span>
        <span v-if="trade && trade != 'undefined'">{{ trade.split(",")[0] }}, </span>
        <span v-if="crime && crime != 'undefined'">{{ crime.split(",")[0] }}, </span> 
        <span v-if="ship && ship != 'undefined'">{{ ship }} {{ arrivalYear }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "EntryCompact",
  components: {},
  props: {
    givenName: String,
    familyName: String,
    country: String,
    age: { default: null },
    trade: String,
    crime: String,
    ship: String,
    arrivalYear: { default: null },
    flag: String,
    iconTrade: String,
    iconCrime: String,
    fmnCount: String,
    viewCount: String,
  },
  methods:{
    cleanName(name){
      if(name=="undefined"){
        return ""
      }else{
        return name
      }
    }
  }
};
</script>

<style scoped lang="scss">
.compact {
  display: flex;
  padding: 0.5em 1.5em;
  align-items: center;
  border-top: 1px solid var(--brick);
  border-bottom: 1px solid var(--brick);
  margin: -1px;
  transition: 0.25s;
}
.compact:hover {
  color: var(--charcoal);
  background-color: var(--warm-sandstone);
  transition: 0.1s;
  .compact-circle {
    background-color: var(--charcoal);
    transition: 0.1s;
  }
  .icon-circle {
    background-color: var(--brick);
    transition: 0.25s;
  }
}
.compact-text {
  width: 100%;
  margin-top: 7px;
  margin-left: 10px;
  z-index: 1;
}
.compact-title {
  display: flex;
  justify-content: space-between;
  text-align: left;
  white-space: nowrap;
}
h3 {
  margin: 0.1em;
  text-transform: uppercase;
}
p {
  margin: 0.1em;
  text-align: left;
}
mark {
  color: var(--grey);
  background-color: transparent;
}
.compact-circle {
  width: 3.5em;
  min-width: 3.5em;
  height: 3.5em;
  border-radius: 100%;
  position: relative;
  background-color: var(--warm-sandstone);
  transition: 0.25s;
}
.icon-circle {
  transform: scale(0.475);
  width: 8em;
  height: 8em;
  border-radius: 20%;
  position: absolute;
  bottom: -65%;
  right: -64%;
  background-color: var(--grey);
}
.flag-circle {
  width: 1.25em;
  height: 1.25em;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--light-charcoal);
  outline: 0.15em solid var(--charcoal);
}
</style>