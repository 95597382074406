<template>
  <div class="header-bar">
    <!-- INFO -->
    <div class="header-button" @click="doShowPopup">
      <h2>INFO</h2>
     
      <img class="header-shape" src="../assets/icons/flower.svg">
    </div>
    <!-- CONVICT MEMORIAL -->
    <div @click="goHome" to="/" style="height: 3.5em;">
      <h2 class="convict-memorial"><mark>CONVICT</mark> MEMORIAL</h2>
    </div>
    <!-- QUEUE -->
    <div v-if="queueType=='simple'" class="header-button" @click="doShowQueue">
      <h2>QUEUE</h2>
      <img class="header-shape" src="../assets/icons/column.svg">
    </div>
    <div v-else class="header-button" @click="doShowQueue">
      <div v-if="nextConIn!='00'" style="display: flex; justify-content: center;">
        <p>NEXT:</p>
        <h2 class="header-timer">{{ nextConIn }}</h2>
      </div>
      <div v-if="nextConIn=='00'" style="display: flex; justify-content: center;">
        <p>UPDATING</p>
       
      </div>
      <img class="header-shape" src="../assets/icons/column.svg">
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderBar",
  mounted(){
    
  },
  computed: {
    user(){
      return this.$store.getters.getUser
    },
    queueType(){
      if(this.isGuide || this.$route.name=="queue-list"){
        return "NextCon"
      }
      return "simple"
    },
    isGuide(){
      if(this.user && (this.user.guide || this.user.super ||  this.user.iPad)){
       return true
      }
      return false
    },
    nextConIn() {
      let conIn=Math.max(0, this.$store.getters.getNextConIn)%60
      return  this.twoDigit(Math.max(0, this.$store.getters.getNextConIn)%60)
    },
    showPopup() {
      return this.$store.getters.getShowPopup
    }
  },
  methods: {
    goHome(){
      if(this.user){
        this.$router.push("/")
      }
    },
    twoDigit(val) {
      if (val < 10) {
        return "0" + val
      } else {
        return val
      }
    },
    showNextIn(){
      return false
    },
    doShowPopup() {
      this.$store.commit("setShowPopup", !this.showPopup)
    },
    doShowQueue(){
      this.$router.push("/queue-list")
    }
  },
};
</script>

<style scoped>
.header-bar {
  display: flex;
  justify-content: space-between;
  font-size: clamp(0.5em, 2.5vw, 1em);
  padding: 1em 2em;
  margin: 1em 0 1em 0;
}
.convict-memorial {
  transform: scale(1.5);
  margin-top: 0.45em;
}
.header-button {
  width: 7em;
  height: 3em;
  border-radius: 0.25em;
  position: relative;
  transition: 0.1s;
}
.header-button:hover {
  color: var(--charcoal);
  background-color: var(--warm-sandstone);
}
.header-shape {
  position: absolute;
  height: 6em;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.1s;
  z-index: -1;
}
h2, h3 {
  margin: 0;
  margin-top: 0.35em;
}
p {
  margin: 0;
  margin-top: clamp(0.75em, 2.75vw, 0.85em);
  margin-right: 0.25em;
}
</style>