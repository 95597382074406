export default {

  data() {
    return {
      flagCode: {
        "Abroad": "undefined",
        "At Sea": "undefined",
        "In Army": "undefined",
        "Africa": "undefined",
        "South America": "undefined",
        "Antigua": "ag",
        "Australia": "au",
        "Austria": "at",
        "Barbados": "bb",
        "Belgium": "be",
        "Bermuda": "bm",
        "Brazil": "br",
        "Canada": "ca",
        "Cape Verde": "cv",
        "China": "cn",
        "Cuba": "cu",
        "Denmark": "dk",
        "Dominica": "dm",
        "England": "gb-eng",
        "France": "fr",
        "Germany": "de", "Prussia": "de",
        "Gibraltar": "gi",
        "Greece": "gr",
        "Grenada": "gd",
        "Guernsey": "gg",
        "Hong Kong": "hk",
        "Hungary": "hu",
        "Honduras": "hn",
        "India": "in",
        "Indonesia": "id", "East Indies": "id",
        "Ireland": "ie",
        "Isle of Man": "im",
        "Italy": "it",
        "Jamaica": "jm",
        "Jersey": "je",
        "Malta": "mt",
        "Mauritius": "mu",
        "Mozambique": "mz",
        "Netherlands": "nl", "Holland": "nl",
        "New Zealand": "nz",
        "Norway": "no",
        "Poland": "pl",
        "Portugal": "pt",
        "Russia": "ru",
        "St Helena": "sh",
        "St Kitts": "kn","Nevis": "kn",
        "Scotland": "gb-sct",
        "South Africa": "za", "Cape Colony": "za", "Cape of Good Hope": "za",
        "Spain": "es",
        "Sri Lanka": "lk", "Ceylon": "lk",
        "Sweden": "se",
        "Switzerland": "ch",
        "Tahiti": "pf",
        "Trinidad and Tobago": "tt",
        "United States": "us", "America": "us",
        "West Indies": "xx",
        "Wales": "gb-wls"
      },
      mainCode: {
        "Servant": "tools", "Labourer": "tools", "Farm Labourer": "tools", "Plough Man": "tools",
        "Boy": "boy",
        "Girl": "girl", "Child": "girl",
        "Baker": "campfire", "Cook": "campfire", "Arson": "campfire",
        "Smith": "anvil",
        "Maid": "judge", "House Maid": "judge", "Kitchen Maid": "judge", "Nurse Maid": "judge", "Cleaner": "judge", "Laundress": "judge", "Bonnet Maker": "judge",
        "Shoe Maker": "bellows",
        "Sailor": "wheel",
        "Paper Maker": "notice",
        "Brick Maker": "bricks",
        "Stealing": "chains",
        "Larceny": "cell",
        "Breaking & Burglary": "whip",
        "Soldier": "guns", "Assault": "guns", "Desertion": "guns",
        "Forgery & Uttering": "quill",
        "Felony": "gallows",
        "undefined": "ship",  "Unclassified": "ship"
      }
    }
  },
  mounted() { },
  computed: {},
  methods: {
    flagIcon(country) {
      return "fib fi-"+this.flagCode[country]+ " fis"
    },
    mainIcon(trade) {
      return this.mainCode[trade]
    },
    personIcon(trade) {
      return this.peopleCode[trade]
    },
    personToIcon(con) {

      if (con) {
        let val = "male"
        let age
        if (con.s == "Female" || con.Gender == "F") {
          val = "female"
        } else {
          val = "male"
        }
        if (con.a) {
          age = parseInt(con.a, 10) || 0
        } else {
          if (con.AgeOnArrival) (
            age = parseInt(con.AgeOnArrival, 10) || 0
          )
        }
        if (age) {
          if (age < 15) {
            val = val + "_10"
          } else if (age < 20) {
            val = val + "_15"
          } else if (age < 30) {
            val = val + "_20"
          } else if (age < 40) {
            val = val + "_30"
          } else {
            val = val + "_40"
          }
        }
        return val
      }
      return null
    },
  }
}
