<template>
  <HeaderBar></HeaderBar>
  <div v-if="selectedCon">
  <EntryCircle v-if="!startCountdown" :givenName="selectedCon.g" :familyName="selectedCon.f" :flag="flagIcon(selectedCon.c)" :iconTrade="personToIcon(selectedCon)" :iconCrime="selectedCon.cr"></EntryCircle>
  <EntryCircleCountdown v-if="startCountdown" :givenName="selectedCon.g" :familyName="selectedCon.f" :time="startTime"></EntryCircleCountdown>
 
  <h3>{{convictName}}</h3>
  
 
  Birth Year{{ birthYear }}
  <h2>Panel A</h2>
  <div v-html="panelA_Text"></div>
  <p v-if="false">{{selectedCon}}</p>
  <h2>Panel B</h2>
  <div>{{ triedLocationImage }}</div>
  
  <div v-html="panelB_Text"></div>
  <div v-if="convictInfo">{{ convictInfo.Items }}</div>
  <h2>Panel C</h2>
  <div>{{ placeImage }}</div>
  
  <div>{{convictImage}}</div>
  <div v-html="panelC_Text"></div>
  <h2>Panel D</h2>
  <div>{{tasLocationImage}}</div>
  <div v-html="panelD_Text"></div>
  <div v-html="offence_Text"></div>
  <p>{{convictInfo}}</p>
  <div v-if="!startCountdown">
    <CustomButton @click="doStartCountdown" :buttonTitle="'CONFIRM'" :buttonIcon="'confirm'" :buttonColour="'red'"></CustomButton>
    <router-link to="/convict-entry"><CustomButton :buttonTitle="'GO BACK'" :buttonIcon="'back'" :buttonColour="'grey'"></CustomButton></router-link>
  </div>
  </div>
  <CustomButton v-if="startCountdown" @click="doStartCountdown" :buttonTitle="'REMOVE FROM QUEUE'" :buttonIcon="'cancel'" :buttonColour="'grey'"></CustomButton>
</template>

<script>
import flags from '@/mixins/flags.js'
import time from '@/mixins/time.js'
import imageBank from '@/mixins/imageBank.js'
import HeaderBar from "@/components/HeaderBar.vue";
import EntryCircle from "@/components/EntryCircle.vue";
import EntryCircleCountdown from "@/components/EntryCircleCountdown.vue";
import EntrySwipe from "@/components/EntrySwipe.vue";
import CustomButton from "@/components/CustomButton.vue";
export default {
  name: "QueueView",
  mixins:[flags,imageBank,time],
  components: {
    HeaderBar,
    EntryCircle,
    EntryCircleCountdown,
    CustomButton,
    EntrySwipe
  },
  data(){
    return {
      startTime:0,
      lastEntry:0,
      //maleImages:null
     
    }
  },
  mounted(){
    setInterval(this.getStartTime,1000)
    console.log("STORY MOUNTED",this.currentCon)
    if(this.currentCon) {
     
      this.$store.dispatch("getConvictById",this.currentCon.id)
    }
  },
  computed: {
    race(){
      console.log("RACE",this.convictInfo.Race)
      if(this.convictInfo && this.convictInfo.Race){
        if(Array.isArray(this.convictInfo.Race)){
          return this.convictInfo.Race[0]
        }
        return this.convictInfo.Race
      }else{
        return null
      }
    },
    gender(){
      if(this.convictInfo && this.convictInfo.Gender){
        if(this.convictInfo.Gender.toLowerCase()=="f" || this.convictInfo.Gender.toLowerCase()=="female"){
          return "Female"
        }else if(this.convictInfo.Gender.toLowerCase()=="m" || this.convictInfo.Gender.toLowerCase()=="male"){
          return "Male"
        }else{
          return "Not Recorded "+this.convictInfo.Gender
        }
      }else{
        return "Not Recorded "+this.convictInfo.Gender
      }
    },
    pronoun(){
      if(this.convictInfo){
        if(this.gender=="Female"){
          return "She"
        }else{
          return "He"
        }
      }else{
        return "They"
      }
    },
    pronounHisHer(){
      if(this.convictInfo){
        if(this.gender=="Female"){
          return "her"
        }else{
          return "his"
        }
      }else{
        return "their"
      }
    },
    birthYear(){
      let year
      if(this.convictInfo){
        if(this.convictInfo.BirthYear){
          year=this.convictInfo.BirthYear
          if(!isNaN(parseInt(year,10))){
            //return parseInt(year,10)
          }
          if(this.convictInfo.AgeOnArrival && this.convictInfo.ArrivalYear){
            year=this.convictInfo.ArrivalYear-this.convictInfo.AgeOnArrival
            if(!isNaN(year)){
              return year
            }
          }
          if(this.convictInfo.DeathDateYear && this.convictInfo.ArrivalYear){
            year=this.convictInfo.DeathDateYear-this.convictInfo.AgeOnArrival
            if(!isNaN(year)){
              return year
            }
          }
        }
      
      }else{
        return 0
      }
    },
    convictName(){
      let name=""
      if(this.convictInfo){
        if(this.convictInfo.GivenNames){
          name=this.convictInfo.GivenNames
        }
        if(this.convictInfo.FamilyName){
          if(name!=""){
            name+=" "
          }
          name+=this.convictInfo.FamilyName
        }
        return name
      }else{
        return null
      }
    },
    
    panelA_Text(){
      let text=""
      let name=""
      if(this.convictInfo){
        if(this.convictInfo.ConvictID){
          text+="ConvictID: "+this.convictInfo.ConvictID
        }
        if(this.convictName){
          name=this.convictName
        }
        text+="<br>"+"Name: "+name
        text+="<br>"+"Gender: "+this.gender
        text+="<br>"+"Views: " +(this.convictInfo.views || 0)
        text+="  - "+"Dedications: "+(this.convictInfo.dedications || 0)
        //
        return text

      }else{
        return null
      }
    },
    panelBLocation(){
      let place=null
      if(this.convictInfo){
        
      if(this.convictInfo.Trial_PlaceCountry_clean){
          if(this.convictInfo.Trial_Place_tscript){
            place=this.convictInfo.Trial_Place_tscript+" "+this.convictInfo.Trial_PlaceCountry_clean
          }else{
            place=this.convictInfo.Trial_PlaceCountry_clean
          }
         
        }else if(this.convictInfo.DeparturePlaceCountry){
          place=this.placeConvert(this.convictInfo.DeparturePlaceCountry)
        }
      }
      return place
    },
    panelB_Text(){
      let text=""
    //Name Age
     //Convicted of XXXX in XXXXX
     //Sentenced to XXXXX Transportation
      if(this.convictInfo){
        if(this.convictName){
          text+=this.convictName
        }
        if(this.convictInfo.AgeOnArrival){
          text+="<br>Age: "+this.convictInfo.AgeOnArrival
        }
        //Trial_PlaceCountry_clean
        if(this.panelBLocation){
            text+="<br>Tried at "+this.panelBLocation
        }
         
        
        //Crime
        if(this.convictInfo.Crimetscript){
          text+="<br>Convicted of "+this.convictInfo.Crimetscript
        }else if(this.convictInfo.Transportedfor){
          text+="<br>Convicted of "+this.convictInfo.Transportedfor
        }
        if(this.convictInfo.SentenceClean){
          text+="<br>Sentence to "+this.convictInfo.SentenceClean +" years transportation"
        }
        if(this.convictInfo.DepartureYear){
          this.lastEntry=this.convictInfo.DepartureYear
          text+="<br>"+this.convictInfo.DepartureYear
        }else if(this.convictInfo.ArrivalYear){
          text+="<br>"+this.convictInfo.ArrivalYear
        }
      }
       //DepartureYear
       
        return text
      
    },
    
    panelC_Text(){
      let text=""
      let description=""
      if(this.convictInfo){
        if(this.convictName){
          text+=this.convictName
        }
        if(this.convictInfo.Trade){
          text+="<br>Trade: "+this.convictInfo.Trade
        }
        if(this.convictInfo.NativePlace){
          text+="<br>From: "+this.placeConvert(this.convictInfo.NativePlace)
        }
        if(this.convictInfo.NP_Country){
          text+="<br>From: "+this.convictInfo.NP_Country
        }
       
        if(this.convictInfo.HeightTotalInches){
          description+="<br> Height: "+this.incheConvert(this.convictInfo.HeightTotalInches)
        }
        if(this.convictInfo.Hair){
          description+="<br> Hair: "+this.convictInfo.Hair
        }
        if(this.convictInfo.Eyes){
          description+="<br> Eyes: "+this.convictInfo.Eyes
        }
        if(this.convictInfo.ConDesc_Marks_tscript){
          description+="<br> Marks: "+this.convictInfo.ConDesc_Marks_tscript
        }
        if(description!=""){
          text+="<br>Description:"
          text+=description
        }else{
          text+="<br>Description:"
          text+="<br>No description noted in the records"
        }
        if(this.convictInfo.ShipName){
            text+="<br>Ship: "+this.convictInfo.ShipName
        }
     
        
        return text
      }else{
        return null
      }
    },
    panelD_Text(){
      let text=""
     
      if(this.convictInfo){
        if(this.convictName){
          text+=this.convictName
        }
        if(this.convictInfo.ArrivalPlaceColony){
          text+="<br>Arrived at "+this.convictInfo.ArrivalPlaceColony
        }else{
          text+="<br>Arrived "
        }
        if(this.convictInfo.ArrivalYear){
          this.lastEntry=this.convictInfo.ArrivalYear
          text+="in "+this.convictInfo.ArrivalYear
        }
        if(this.convictInfo.ShipName){
            text+=" aboard the "+this.convictInfo.ShipName
        }
        if(this.convictInfo.Offences_count){
          if(this.convictInfo.Offences_count>1){
            text+="<br>Commited "+this.convictInfo.Offences_count+" offences"
          }else{
            text+="<br>Commited "+this.convictInfo.Offences_count+" offence"
          }
        }else{
          text+="<br>No offences had been recorded against "+this.pronounHisHer+" name"
        }
        if(this.convictInfo.Offences_from && this.convictInfo.Offences_to){
          this.lastEntry=this.convictInfo.Offences_to
          let numYears=this.convictInfo.Offences_to-this.convictInfo.Offences_from+1
          if(numYears>1){
            text+=" over "+numYears+ " years"
          }else{
            text+=" in "+this.convictInfo.Offences_from
          }
         
        }
        if(this.convictInfo.town){
          text+="<br>TOWNS: "+this.convictInfo.town
        }
        if(this.convictInfo.place){
          text+="<br>PLACE: "+this.convictInfo.place
        }
        if(this.convictInfo.Offences_lashes){
          text+="<br>Received "+this.convictInfo.Offences_lashes+" lashes"
        }
       
        let mariages=0
        if(this.convictInfo.wife){
          mariages=this.convictInfo.wife
        }
        if(this.convictInfo.husband){
          mariages=this.convictInfo.husband
         
        }
        if(mariages==1){
          text+="<br>Married Once"
        }else if(mariages>1){
          text+="<br>Married "+mariages+ " times"
        }
        if(this.convictInfo.children){
          if(this.convictInfo.son+this.convictInfo.daughter==this.convictInfo.children){
            text+="<br>Children: "+this.convictInfo.children
          }else{
            text+="<br>Children: "+this.convictInfo.children
          }
        }
        if(isNaN(this.convictInfo.DeathAge) && this.convictInfo.DeathDateYear && this.birthYear){
          this.convictInfo.DeathAge=this.convictInfo.DeathDateYear - this.birthYear

        }
        if(this.convictInfo.DeathDateYear && this.convictInfo.DeathAge){
          this.lastEntry=this.convictInfo.DeathDateYear

          text+="<br>Died "+this.convictInfo.DeathDateYear+" aged "+this.convictInfo.DeathAge
        }else{
          if(this.convictInfo.DeathAge){
            text+="<br>Died aged "+this.convictInfo.DeathAge
          }
          if(this.convictInfo.DeathDateYear){
            this.lastEntry=this.convictInfo.DeathDateYear
            text+="<br>Died in "+this.convictInfo.DeathDateYear
          }
        }
        //DeathCause
        if(this.convictInfo.DeathCause){
            text+="<br>Cause of Death "+this.convictInfo.DeathCause
          }
        
        if(this.convictInfo.DeathPlaceCountry && this.convictInfo.DeathDiedUnderSentence=="yes"){
          text+="<br>"+this.pronoun+" died in "+this.convictInfo.DeathPlaceCountry+" and was sill under sentence"
        }else{
          if(this.convictInfo.DeathPlaceCountry ){
            text+="<br>"+this.pronoun+" died in "+this.convictInfo.DeathPlaceCountry
          }
          if(this.convictInfo.DeathDiedUnderSentence && this.convictInfo.DeathDiedUnderSentence=="yes" ){
            text+="<br>"+this.pronoun+" was sill under sentence"
          }
          
        }
        if(this.lastEntry && !this.convictInfo.DeathDateYear){
            text+="<br>Last Entry "+this.lastEntry
          }
        
        //Offence_tscrip
        
        return text

      }else{
        return null
      }
    },
    offence_Text(){
      let text=""
     
      if(this.convictInfo){
        
        
       
       
        
        if(this.convictInfo.Offence_tscript){
          text+="<br><br><br><br><br>Offences<br>"+this.convictInfo.Offence_tscript.split("|").join("<br>")
        }
        if(this.convictInfo.Offences_Sentence){
          text+="<br><br>Offences Sentence<br>"+this.convictInfo.Offences_Sentence.split("|").join("<br>")
        }
        
        
        //Offence_tscrip
        
        return text

      }else{
        return null
      }
    },
    queueLength(){
      return this.$store.getters.getQueueLength
    },
    selectedCon(){
      return this.$store.getters.getSelectedCon
    },
    startCountdown() {
      return this.$store.getters.getStartCountdown
    },
    convictInfo(){
      return this.$store.getters.getConvict
    },
    currentCon(){
      if(this.possibleCons){
        return this.possibleCons[this.possibleIndex]
      }
    },
    possibleCons() {
      return this.$store.getters.getPossibleConvicts
    },
    possibleIndex() {
      return this.$store.getters.getPossibleIndex
    }
  },
  methods: {
    
   
    
    placeConvert(val){
      let places=val.split(",")
      let place=""
      let hasLoc=false
      for(var p in places){
        if(places[p] && places[p].length>1){
          if(p==2 && hasLoc && places[3]){
           
          }else{
            place+=places[p]+","
            hasLoc=true
          }
        }

      }
      return place.substring(0, place.length-1)
    },
    incheConvert(val){
      let parts=val.split(".")
      let feet=Math.floor(parts[0]/12)
      let inches =parts[0]-feet*12
      let part=parts[1] || null
      if(part==5){
        part="1/2"
      }else if(part==25){
        part="1/4"
      }else if(part==75){
        part="3/4"
      }
      return feet+"ft "+inches+" "+part+"in "


    },
    doSwipe(val){
      this.$store.commit("setPossibleIndex",val)
    },
    
    async doStartCountdown() {
      this.$store.dispatch("queueConvict",this.selectedCon)
      
      console.log("GETTER",this.$store.getters.getConvictById(this.selectedCon.id))
      console.log("QUEUE CON",this.selectedCon)

      this.$store.commit("setStartCountdown", !this.startCountdown)
    },
  },
  
    selectedConCon(val) {
      console.log("selectedCon",val)
      this.remembered=false
      this.$store.dispatch("getConvictById",val.id)
    }
};
</script>