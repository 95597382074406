
import { createApp } from 'vue'
import App from './App.vue'
//import router from './router'
import store from './store'
import 'firebase/firestore';
import firebase from 'firebase/compat/app';
import { getAuth } from 'firebase/auth'
import { createRouter } from './router'


 const app=createApp(App).use(store)
 app.config.compilerOptions.isCustomElement = (tag) => {
  return tag.startsWith('a-')
}
app.config.compilerOptions.isCustomElement = (tag) => {
  return tag.startsWith('ion-')
}

const router = createRouter(app)
app.use(router)

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCMFtYqJ3Gwln_rakucFAxjep8CUwkUFPk",
  authDomain: "convictmemorial.firebaseapp.com",
  databaseURL: "https://convictmemorial-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "convictmemorial",
  storageBucket: "convictmemorial.appspot.com",
  messagingSenderId: "239757065703",
  appId: "1:239757065703:web:4f6186573117e4126c5142",
  measurementId: "G-NQHJGL40MD"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.auth().onAuthStateChanged(user => {
  
  if (user) {
    store.dispatch('autoSignIn', user)
  }
})
console.log("STORE",app.config)
const Store=app.config
router.store=store
app.mount('#app')