<template>
  <HeaderBar></HeaderBar>
  <div v-if="selectedCon">
  <EntryCircle v-if="!startCountdown" :givenName="selectedCon.g" :familyName="selectedCon.f" :flag="flagIcon(selectedCon.c)" :iconTrade="personToIcon(selectedCon)" :iconCrime="selectedCon.cr"></EntryCircle>
  <EntryCircleCountdown v-if="startCountdown" :givenName="selectedCon.g" :familyName="selectedCon.f" :time="startTime"></EntryCircleCountdown>
  {{ queueLength }}
  <h3>Next column spot available in 01:03</h3>
  <h2>Add to Queue?</h2>
  <div v-if="!startCountdown">
    <CustomButton @click="doStartCountdown" :buttonTitle="'CONFIRM'" :buttonIcon="'confirm'" :buttonColour="'red'"></CustomButton>
    <router-link to="/convict-entry"><CustomButton :buttonTitle="'GO BACK'" :buttonIcon="'back'" :buttonColour="'grey'"></CustomButton></router-link>
   
  </div>
  <CustomButton v-if="startCountdown" @click="doStartCountdown" :buttonTitle="'REMOVE FROM QUEUE'" :buttonIcon="'cancel'" :buttonColour="'grey'"></CustomButton>
   </div>
</template>

<script>
import flags from '@/mixins/flags.js'
import time from '@/mixins/time.js'
import HeaderBar from "@/components/HeaderBar.vue";
import EntryCircle from "@/components/EntryCircle.vue";
import EntryCircleCountdown from "@/components/EntryCircleCountdown.vue";
import CustomButton from "@/components/CustomButton.vue";
export default {
  name: "QueueView",
  mixins:[flags,time],
  components: {
    HeaderBar,
    EntryCircle,
    EntryCircleCountdown,
    CustomButton,
  },
  data(){
    return {
      startTime:0
    }
  },
  mounted(){
    setInterval(this.getStartTime,1000)
  },
  computed: {
    user(){
      return this.$store.getters.getUser
    },
    singleUser(){
      console.log("USER",this.user)
      if(this.user){
       return false
      }
      return true
     
    },
    queueLength(){
      return this.$store.getters.getQueueLength
    },
    selectedCon(){
      return this.$store.getters.getSelectedCon
    },
    startCountdown() {
      return this.$store.getters.getStartCountdown
    },
  },
  methods: {
    
    async doStartCountdown() {
     
      this.$store.dispatch("queueConvict",this.selectedCon)
      if(this.singleUser){
        console.log("GETTER",this.$store.getters.getConvictById(this.selectedCon.id))
        console.log("QUEUE CON",this.selectedCon)

      this.$store.commit("setStartCountdown", !this.startCountdown)
      }else{
        console.log("GO TO QUEUE")
        this.router.push("/queue-list")
      }
     
    },
  },
};
</script>