//import { createRouter, createWebHistory } from 'vue-router'
import { createRouter as createVueRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import SearchView from '../views/SearchView.vue'
import ConvictEntryView from '../views/ConvictEntryView.vue'
import QueueView from '../views/QueueView.vue'
import QueueListView from '../views/QueueListView.vue'
import NowPlayingView from '../views/NowPlayingView.vue'
import Story from '../views/Story.vue'

let router
let store

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: function () {
      return import(/* webpackChunkName: "stories" */ '../views/Login.vue')
    },
    beforeEnter: (to, from, next) => {
    
      if (store.getters.getLoggedIn) {
      
        store.dispatch("logout")
      }else{
       
        next()
      }
      //next()
       //store.dispatch("logout")
       // next({ path: '/' })
     
    }
    
  },
  {
    path: '/search',
    name: 'search',
    component: SearchView
  },
  {
    path: '/convict-entry',
    name: 'convict-entry',
    component: ConvictEntryView
  },
  {
    path: '/queue',
    name: 'queue',
    component: QueueView
  },
  {
    path: '/queue-list',
    name: 'queue-list',
    component: QueueListView
  },
  {
    path: '/now-playing',
    name: 'now-playing',
    component: NowPlayingView
  },
  {
    path: '/guide',
    name: 'guide',
    component: function () {
      return import(/* webpackChunkName: "stories" */ '../views/GuideView.vue')
    },
    beforeEnter: (to, from, next) => {
     
      store.commit("setLoginType",0)
     
      if (store.getters.getLoggedIn) {
        if(store.getters.getUser){
          if(store.getters.getUser && (store.getters.getUser.guide || store.getters.getUser.super)){
            console.log("CAN GUIDE")
            next()
          }else{
            next({ path: '/login' })
          }
        }else{
          next({ path: '/login' })
        }
      } else {
        console.log("ROUTER",this)
        store.commit("setloginPath",'/guide')
        next({ path: '/login' })
      }
    }

  },
  {
    path: '/bespoke',
    name: 'bespoke',
    component: function () {
      return import(/* webpackChunkName: "stories" */ '../views/Bespoke.vue')
    },
    beforeEnter: (to, from, next) => {
     
      store.commit("setLoginType",0)
     
      if (store.getters.getLoggedIn) {
        if(store.getters.getUser){
          if(store.getters.getUser && (store.getters.getUser.guide || store.getters.getUser.super)){
           
            next()
          }else{
            next({ path: '/login' })
          }
        }else{
          next({ path: '/login' })
        }
      } else {
       
        store.commit("setloginPath",'/guide')
        next({ path: '/login' })
      }
    }

  },
  {
    path: '/logout',
    name: 'logout',
    component: function () {
      return import(/* webpackChunkName: "stories" */ '../views/HomeView.vue')
    },
    beforeEnter: (to, from, next) => {
      store.commit("setLoginType",0)
     
       store.dispatch("logout")
        next({ path: '/' })
     
    }
  },
  {
    path: '/memorial',
    name: 'memorial',
    component: function () {
      return import(/* webpackChunkName: "stories" */ '../views/HomeView.vue')
    },
    beforeEnter: (to, from, next) => {
      store.commit("setLoginType",3)
      if (store.getters.getLoggedIn) {
        if(store.getters.getUser){
          if(store.getters.getUser && (store.getters.getUser.guide || store.getters.getUser.super)){
            console.log("CAN GUIDE")
            next()
          }else{
            next({ path: '/login' })
          }
        }else{
          next({ path: '/login' })
        }
      } else {
        console.log("ROUTER",this)
        store.commit("setloginPath",'/')
        next({ path: '/login' })
      }
    }
  },
  {
    path: '/interventions',
    name: 'interventions',
    component: function () {
      return import(/* webpackChunkName: "stories" */ '../views/Interventions.vue')
    },
    beforeEnter: (to, from, next) => {
      store.commit("setLoginType",0)
      if (store.getters.getLoggedIn) {
        if(store.getters.getUser){
          if(store.getters.getUser && (store.getters.getUser.guide || store.getters.getUser.super)){
            console.log("CAN GUIDE")
            next()
          }else{
            next({ path: '/login' })
          }
        }else{
          next({ path: '/login' })
        }
      } else {
        console.log("ROUTER",this)
        store.commit("setloginPath",'interventions')
        next({ path: '/login' })
      }
    }
  },
  {
    path: '/weather',
    name: 'weather',
    component: function () {
      return import(/* webpackChunkName: "stories" */ '../views/Weather.vue')
    },
    beforeEnter: (to, from, next) => {
      store.commit("setLoginType",2)
      if (store.getters.getLoggedIn) {
        if(store.getters.getUser){
          console.log("CHECKING USER 1",store.getters.getUser)
          if(store.getters.getUser && (store.getters.getUser.super)){
            console.log("CAN All")
            next()
          }else{
            next({ path: '/login' })
          }
        }else{
          next({ path: '/login' })
        }
      } else {
        console.log("ROUTER",this)
        store.commit("setloginPath",'weather')
        next({ path: '/login' })
      }
    }
  
  },
  {
    path: '/hardware',
    name: 'hardware',
    component: function () {
      return import(/* webpackChunkName: "stories" */ '../views/Hardware.vue')
    },
    beforeEnter: (to, from, next) => {
      store.commit("setLoginType",2)
      if (store.getters.getLoggedIn) {
        if(store.getters.getUser){
          console.log("CHECKING USER 1",store.getters.getUser)
          if(store.getters.getUser && (store.getters.getUser.super)){
            console.log("CAN All")
            next()
          }else{
            next({ path: '/login' })
          }
        }else{
          next({ path: '/login' })
        }
      } else {
        console.log("ROUTER",this)
        store.commit("setloginPath",'hardware')
        next({ path: '/login' })
      }
    }

  },
  {
    path: '/lighting',
    name: 'lighting',
    component: function () {
      return import(/* webpackChunkName: "stories" */ '../views/Lighting.vue')
    },
    beforeEnter: (to, from, next) => {
      // Check if the user has the required role
      console.log("CHECKING USER 0")
      store.commit("setLoginType",1)
      if (store.getters.getLoggedIn  ) {
        if(store.getters.getUser){
          console.log("CHECKING USER 1",store.getters.getUser)
          if(store.getters.getUser && (store.getters.getUser.lighting || store.getters.getUser.guide || store.getters.getUser.super)){
            console.log("CAN LIGHT")
            next()
          }else{
            next({ path: '/login' })
          }
        }else{
          next({ path: '/login' })
        }
      } else {
        store.commit("setloginPath",'lighting')
        next({ path: '/login' })
      }
    }

  },
  {
    path: '/launch',
    name: 'launch',
    component: function () {
      return import(/* webpackChunkName: "stories" */ '../views/Launch.vue')
    },
    beforeEnter: (to, from, next) => {
      // Check if the user has the required role
      console.log("CHECKING USER 0")
      store.commit("setLoginType",1)
      if (store.getters.getLoggedIn  ) {
        if(store.getters.getUser){
          console.log("CHECKING USER 1",store.getters.getUser)
          if(store.getters.getUser && (store.getters.getUser.lighting || store.getters.getUser.guide || store.getters.getUser.super)){
            console.log("CAN LIGHT")
            next()
          }else{
            next({ path: '/login' })
          }
        }else{
          next({ path: '/login' })
        }
      } else {
        store.commit("setloginPath",'launch')
        next({ path: '/login' })
      }
    }

  },
  {
    path: '/story',
    name: 'story',
    component: Story
  },
  {
    path: '/promo',
    name: 'promo',
    component: function () {
     return import(/* webpackChunkName: "stories" */ '../views/Video3.vue')
    }
  },
  {
    path: '/history',
    name: 'history',
    component: function () {
     return import(/* webpackChunkName: "stories" */ '../views/Video2.vue')
    }
  },
]
export const createRouter = (app) => {
  console.log("APP",app.config.globalProperties.$store)
  store=app.config.globalProperties.$store
  return createVueRouter({
    history: createWebHistory(),
    routes: routes,
  })
}


