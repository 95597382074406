<template>
  <div :class="'compact ' + isActive">
    <div class="compact-circle">
      <div :class="'icon-circle ' + iconTrade"></div>
      <div v-if="flag && flag!='fib fis' && flag.indexOf('undefined')==-1" :class="'flag-circle ' + flag"></div>
      <div v-else></div>
    </div>
    <div class="compact-text">
      <div class="compact-title">
        <h2>{{ cleanName(givenName) }} {{ familyName }}</h2>
      </div>
    </div>
    <div class="compact-text" style="width: 11em;">
      <div class="compact-status">
        <h3 v-if="queueStatus">{{ queueStatus }}</h3>
        <p v-if="queueStatus">{{ queueSub }}</p> 
      </div>
    </div>
  </div> 
</template>

<script>
export default {
  name: "QueueCompact",
  components: {},
  props: {
    givenName: String,
    familyName: String,
    isActive: String,
    flag: String,
    iconTrade: String,
    iconCrime: String,
    queueStatus: String,
    queueSub: String,
    queuePos:Number,
    id:null
  },
  methods:{
    cleanName(name){
      if(name=="undefined"){
        return ""
      }else{
        return name
      }
    },
  }
};
</script>

<style scoped lang="scss">
.compact {
  display: flex;
  padding: 0.5em 1.5em;
  align-items: center;
  border-top: 1px solid var(--brick);
  border-bottom: 1px solid var(--brick);
  margin: -1px;
  transition: 0.25s;
}
.active {
  color: var(--charcoal);
  background-color: var(--warm-sandstone);
  transition: 0.1s;
  .compact-circle {
    background-color: var(--charcoal);
    transition: 0.1s;
  }
  .icon-circle {
    background-color: var(--brick);
    transition: 0.25s;
  }
}
.compact-text {
  width: 100%;
  margin-top: 7px;
  margin-left: 10px;
  z-index: 1;
}
.compact-title {
  display: flex;
  justify-content: space-between;
  text-align: left;
  white-space: nowrap;
}
.compact-status {
  text-align: center;
  white-space: nowrap;
}
h2, h3 {
  margin: 0.1em;
  text-transform: uppercase;
}
p {
  margin: 0.1em;
}
mark {
  color: var(--grey);
  background-color: transparent;
}
.compact-circle {
  width: 3.5em;
  min-width: 3.5em;
  height: 3.5em;
  border-radius: 100%;
  position: relative;
  background-color: var(--warm-sandstone);
  transition: 0.25s;
}
.icon-circle {
  transform: scale(0.475);
  width: 8em;
  height: 8em;
  border-radius: 20%;
  position: absolute;
  bottom: -65%;
  right: -64%;
  background-color: var(--grey);
}
.flag-circle {
  width: 1.25em;
  height: 1.25em;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--light-charcoal);
  outline: 0.15em solid var(--charcoal);
}
</style>