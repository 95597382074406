<template>
  <HeaderBar></HeaderBar>
  <div v-if="possibleCons">
   
    <EntryCountdown v-if=" isQueued && !playingPanel && queueLength > 0"></EntryCountdown> <!-- New Design in Here -->
    <EntryCircleCountdown v-if="false && isQueued && !playingPanel && queueLength > 0" @click="showQueueList"></EntryCircleCountdown> <!-- Remove after transition to new design -->
    <!-- CONVICT CIRCLE -->
    <EntryCircle v-if="!playingPanel && (!isQueued && (playingPanel==0 && !isStarted) )"
                  @click="showQueueList" :givenName="cleanName(currentCon.g)" :familyName="currentCon.f"
                  :flag="'fib ' + flagIcon(currentCon.c) + ' fis'" :iconTrade="personToIcon(currentCon)" :iconCrime="''">
    </EntryCircle>
   
    <div v-if="singleUser">
      <div v-if="playingPanel>0 || isStarted" class="countdown-box" >
      <h1  class="countdown-timer">{{ panelTitle }}</h1>
      <h2 v-if="playingPanel>0">ON SCREEN {{ playingPanel }}: THE RECORDS</h2>
      <p v-html="playingText"></p>
      </div>
    </div>
    <!-- CONVICT TEXT -->
    <div v-if="!showSwipe" @click="showConvictStory" class="convict-name"><h2>{{ cleanName(currentCon.g) +' '+ currentCon.f }}</h2></div>
    <EntrySwipe v-if="showSwipe" class="convict-name" :items="possibleCons" :index="possibleIndex" @swipe="doSwipe"
                @labelClick="showConvictStory" :labelText="cleanName(currentCon.g) +' '+ currentCon.f">
    </EntrySwipe>
    <EntryStats v-if="convictInfo" :fmnCount="convictInfo.dedications" :viewCount="convictInfo.count" :newDedication="remembered"></EntryStats>
    <EntryDetails :country="currentCon.c" :gender="currentCon.s" :age="currentCon.a" :trade="getTrade(currentCon)"
                  :crime="getCrime(currentCon)" :ship="currentCon.sh" :arrivalYear="currentCon.ya"
                  :flag="'fib ' + flagIcon(currentCon.c) + ' fis'" :iconTrade="tradeToIcon(currentCon)" :iconCrime="''">
    </EntryDetails>
    <!-- BUTTONS -->
    <div v-if="!(isQueued && queueLength == 0) && playingPanel == 0">
      <div v-if="!isQueued || testing">
          <CustomButton @click="addToQueue" :buttonTitle="'ADD TO QUEUE'" :buttonIcon="'add-to-queue'" :buttonColour="'red'"></CustomButton>
      </div>
    </div>
    
  </div>
  <CustomButton v-if="isQueued || isStarted" @click="doRemember" :disabled="remembered" :buttonTitle="'REMEMBER ME'" :buttonIcon="'forget-me-not'" :buttonColour="'blue'"></CustomButton>
  <router-link v-if="!isQueued && !isStarted" to="/search"><CustomButton :buttonTitle="'BACK TO SEARCH'" :buttonIcon="'back'" :buttonColour="'grey'"></CustomButton></router-link>
</template>

<script>
import flags from '@/mixins/flags.js';
import HeaderBar from "@/components/HeaderBar.vue";
import EntryCircleCountdown from "@/components/EntryCircleCountdown.vue";
import EntryCountdown from "@/components/EntryCountdown.vue";
import EntryCircle from "@/components/EntryCircle.vue";
import EntrySwipe from "@/components/EntrySwipe.vue";
import EntryStats from "@/components/EntryStats.vue";
import EntryDetails from "@/components/EntryDetails.vue";
import CustomButton from "@/components/CustomButton.vue";
import "/node_modules/flag-icons/css/flag-icons.min.css";
export default {
  name: "ConvictEntryView",
  mixins: [flags],
  components: {
    HeaderBar,
    EntryCircleCountdown,
    EntryCountdown,
    EntryCircle,
    EntrySwipe,
    EntryStats,
    EntryDetails,
    CustomButton,
  },
  data() {
    return {
     
      isQueued: false,
      time: 59,
      remembered: false,
      isStarted:false,
      lastPanel:0,
      isFinished:false
    }
  },
  mounted(){
    if(!this.possibleCons){
      this.$router.push("/search")
    }
  },
  computed: {
    panelTitle(){
      if(this.playingPanel==0){
        if(this.isFinished){
          return "FINISHED"
        }
        return "MOVING"
      }else if(this.playingPanel==1){
        return "SEARCHING"
      }else if(this.playingPanel>1){
        return "PLAYING"
      }
    },
    playingText(){
      if(this.playingPanel==0){
        if(this.isFinished){
          return "Your convict has finished playing."
        }
        return "Follow the name around the column!"
      }else if(this.playingPanel==1){
        return "Wait for your convict's name to appear.<br> Follow the name around the column when it moves!"
      }else if(this.playingPanel>1){
        return "Follow the name around the column when it moves!"
      }
    },
    user() {
      return this.$store.getters.getUser
    },

    isAdmin(){
      let valid=false
      if(this.user){
        if(this.user.super){
          valid=true
        }
      }
      return valid
    },
    singleUser() {
      if (this.user) {
        return false
      }
      return true
    },
    showSwipe(){
      if(this.isQueued || this.playingPanel){
        return false
      }
      return true
    },
    testing() {
      return this.$store.getters.getTisting
    },
    panelList() {
      return this.$store.getters.getPanelList
    },
    playingPanel() {
      let playing = 0
      if (this.currentCon) {
        for (var p in this.panelList) {
          if (this.panelList[p] == this.currentCon.id) {
            playing = parseInt(p, 10) + 1
            this.isQueued = false
            this.isStarted =true
            this.lastPanel=playing
          }
        }
      }
      if(playing==0 && this.lastPanel==4 && this.isStarted){
        this.isFinished=true
      }
      return playing
    },
    queueLength() {
      return this.$store.getters.getQueueLength
    },
    startTime() {
      return this.$store.getters.getStartTime
    },
    hasNext() {},
    convictInfo() {
      return this.$store.getters.getConvict
    },
    currentCon() {
      if (this.possibleCons) {
        return this.possibleCons[this.possibleIndex]
      }
    },
    possibleCons() {
      return this.$store.getters.getPossibleConvicts
    },
    possibleIndex() {
      return this.$store.getters.getPossibleIndex
    },
    
  },
  methods: {
    cleanName(name){
      if(name=="undefined"){
        return ""
      }else{
        return name
      }
    },
   
    showConvictStory() {
      if(this.isAdmin){
        this.$router.push("story")
      }
     
    },
    tradeToIcon(con) {
      let trade = this.getTrade(con)
      if (trade) {
        trade = trade.toLowerCase()
        if (trade.indexOf("smith") >= 0) {
          return "anvil"
        }
      }
      return null
    },
    getCrime(con) {
      if (this.convictInfo && this.convictInfo.Crime) {
        if (Array.isArray(this.convictInfo.Crime)) {
          return this.convictInfo.Crime[0]
        } else {
          return this.convictInfo.Crime
        }
      } else {
        if (con.cr == "undefined") {
          return null
        } else {
          return con.cr
        }
      }
    },
    getTrade(con) {
      if (this.convictInfo && this.convictInfo.Trade) {
        if (Array.isArray(this.convictInfo.Trade)) {
          return this.convictInfo.Trade[0]
        } else {
          return this.convictInfo.Trade
        }
      } else {
        if (con.t == "undefined") {
          return null
        } else {
          return con.t
        }
      }
    },
    doRemember(){
      let alert = {
        type: "Confirm",
        confirmAction: this.confirmRemember,
        text: ``,
        id: "REMEMBER"
      }
      console.log(alert)
      this.$store.commit("setAlert", alert)
    },
    confirmRemember() {
     
      this.remembered = true
      this.$store.commit("setAlert", false)
      if (this.convictInfo) {
        if (!this.convictInfo.dedications || this.convictInfo.dedications == "undefined") {
          this.convictInfo.dedications = 0
        }
        this.convictInfo.dedications++
        this.$store.dispatch("rememberConvict", this.convictInfo)
      }
    },
    tick() {},
    nextItem() {
      this.currentIndex = (this.currentIndex + 1) % this.items.length
    },
    prevItem() {
      this.currentIndex = (this.currentIndex - 1 + this.items.length) % this.items.length
    },
    doSwipe(val) {
      this.$store.commit("setPossibleIndex", val)
    },
    addToQueue() {
      let alert = {
        type: "Confirm",
        confirmAction: this.doConfirm,
        text: `Add ${this.cleanName(this.currentCon.g).toUpperCase()} ${this.currentCon.f.toUpperCase()} to the Queue?`,
        id:"QUEUE"
      }
      console.log(alert)
      this.$store.commit("setAlert", alert)
      // this.$router.push("/queue")
    },
    async doConfirm() {
      console.log("Confirm", this.singleUser)
      if (this.singleUser) {
        this.$store.commit("setAlert", false)
        this.$store.dispatch("queueConvict", this.currentCon)
       
        this.isQueued = true
        
      } else {
        this.$store.commit("setAlert", false)
        
        this.$store.dispatch("queueConvict", this.currentCon)
        
       //this.doRemember()
       setTimeout(this.doRemember,200)
       setTimeout(this.showQueueList,1000)
       
      

      }
    },
    
    showQueueList() {
      this.$router.push("/queue-list")
    }
  },
  watch: {
    convictInfo(val) {
      console.log("NEW INFO", val)
    },
    currentCon(val) {
      this.remembered = false
      this.$store.dispatch("getConvictById", val.id)
    }
  }
};
</script>

<style lang="scss" scoped>
.countdown-box {
  width: 100%;
  padding: 2em 15% 1.5em;
  margin-bottom: 3em;
  background-color: var(--brick);
  position: relative;
}
.countdown-box::after {
  content: "";
  position: absolute;
  width: 1000%;
  top: 0;
  bottom: 0;
  left: -500%;
  right: -500%;
  background-color: var(--brick);
  z-index: -9999;
}
.countdown-timer {
  font-size: clamp(3.5em, 12vw, 5em);
}
mark {
  font-size: 0.75em;
  color: var(--warm-sandstone);
}
h1, h2, p {
  margin: 0;
}
h2 {
  margin-bottom: 0.3em;
}


</style>