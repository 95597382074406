<template>
  <nav v-if="showNav" class="navBar">
    <router-link to="/"><p>HOME</p></router-link>
    <router-link v-if="isAdmin" to="/search"><p>SEARCH</p></router-link>
    <router-link v-if="false" to="/convict-entry"><p>CONVICT ENTRY</p></router-link>
    <router-link v-if="false" to="/queue"><p>ADD QUEUE</p></router-link>
    <router-link to="/queue-list"><p>QUEUE</p></router-link>
    <router-link v-if="isClient" to="/now-playing"><p>NOW PLAYING</p></router-link>
    <router-link to="/bespoke"><p>BESPOKE CONVICTS</p></router-link> 
    <router-link to="/interventions"><p>INTERVENTIONS</p></router-link> 
    <router-link v-if="isAdmin" to="/weather"><p>WEATHER</p></router-link> 
    <router-link v-if="isAdmin" to="/hardware"><p>HARDWARE</p></router-link>
    <div class="logoutBtn" @click="logout"><p>LOGOUT</p></div>
  </nav>
  <router-view v-slot="{ Component }">
    <!--keep-alive-->
      <component :is="Component" :key="$route.fullPath"></component>
    <!--/keep-alive-->
    <div style="margin-bottom: 2.5em;"></div>
  </router-view>
  <PopupBox v-if="showPopup"></PopupBox>
  <AlertBoxM v-if="showMAlert"></AlertBoxM>
  <AlertBoxR v-if="showRAlert"></AlertBoxR>
  <LoginBox v-if="showLogin"></LoginBox>
  <div v-if="hideScreen" class="Black"></div>
</template>

<script>
import PopupBox from "@/components/PopupBox.vue"
import AlertBoxM from "@/components/AlertBoxM.vue"
import AlertBoxR from "@/components/AlertBoxR.vue"
import LoginBox from "@/components/LoginBox.vue"
export default {
  name: "App",
  components: {
    PopupBox,
    AlertBoxM,
    AlertBoxR,
    LoginBox
  },
  data() {
    return {
      date: null,
      seconds: 0,
      minutes: 0,
      hours: 0,
      showSmallPop: false,
      isListening:false,
    }
  },
  mounted() {
    this.$store.dispatch("initListeners")
  },
  computed: {
    FTB(){
      return this.$store.getters.getFTB
    },
    user() {
      return this.$store.getters.getUser
    },
    isClient(){
      if(this.user){
        return false
      }else{
        return true
      }
    },
    isAdmin(){
      let valid=false
      if(this.user){
        if(this.user.super){
          valid=true
        }
      }
      return valid
    },
    reset(){
      return this.$store.getters.getReset
    },
    showNav(){
      let show=false
      if(this.user && !this.user.iPad) {
       
        show=true
      }
      if(this.$route.name=="lighting" || this.$route.name=="launch" || this.$route.name=="home" || this.$route.name=="promo" || this.$route.name=="history"){
        show=false
      }
      return show
    },
    loggedIn(){
      return this.$store.getters.getLoggedIn
    },
    userEmail(){
      if(this.user){
        return this.user.email
      }
    },
    panelTime() {
      return this.$store.getters.getPanelTime
    },
    timeX() {
      return this.$store.getters.getTime
    },
    startTime() {
      return this.$store.getters.getStartTime
    },
    showPopup() {
      return this.$store.getters.getShowPopup
    },
    alert(){
      return this.$store.getters.getAlert
    },
    showAlert(){
      return this.$store.getters.getShowAlert
    },
    showRAlert() {
      if(this.showAlert && this.alert && this.alert.id=="REMEMBER"){
        return true
      }
    },
    showMAlert() {
      if(this.showAlert && this.alert && this.alert.id=="QUEUE"){
        return true
      }
    },
    showLogin() {
      return this.$store.getters.getShowLogin
    },
    hideScreen(){
      if(this.FTB && this.user && this.user.name=="iPad"){
        return true
      }
      return false
    }
  },
  methods: {
    logout() {
      this.$store.commit("setLoginPath","/")
      this.$router.push("/")
      this.$store.dispatch("logout")
    },
    sTimeToSec(time){
      let t=time.split(":")
        return parseInt(t[0],10)*60*60+parseInt(t[1],10)*60+parseInt(t[2],10)
    },
    tick() {
      let t
      this.date = new Date()
      this.seconds = this.date.getSeconds()
      this.minutes = this.date.getMinutes()
      this.hours = this.date.getHours()
      t = this.seconds + this.minutes * 60 + this.hours * 60 * 60
      this.$store.commit('setTime', t)
    },
    
  },
  watch:{
    user(val){
      if(!this.isListening){
        console.log("USER",val)
        //if(val.name!="iPad"){
          
          this.isListening=true
         // this.$store.dispatch("initListeners")
         // setInterval(this.tick, 500)
       // }
      }
     
    },
    time(val){
      if(this.startTime){
        this.$store.commit("setNextConIn",this.panelTime-(this.sTimeToSec(this.startTime),val,val-this.sTimeToSec(this.startTime)))
        }
    },
    loggedIn(val){
      if(!val){
        this.$router.push("/")
      }
    }
  }
};
</script>

<style lang="scss">
:root {
  --app-height: 100%;
  --app-width: 768px;
  max-width: var(--app-width);
  margin: 0 auto;
}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
  font-weight: 400;
}
#app {
  font-family: DIN Condensed Bold, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 110vh;
  text-align: center;
  color: var(--warm-sandstone);
}
.Black{
  position:absolute;
  width:100vw;
  height:100vh;
  top:0px;
  left:0px;
  background-color: #000;
  z-index:9999;
}
nav {
  display: flex;
  width: 100%;
  z-index: 1000;
  gap: 4px;
  background-color: var(--charcoal);
  margin-bottom: 1em;
  a {
  color: var(--warm-sandstone);
  background-color: var(--brick);
  transition: 0.1s;
  border-radius: 0 0 0.25em 0.25em;
  flex-grow: 1;
    &.router-link-exact-active {
      color: var(--charcoal);
      background-color: var(--warm-sandstone);
    }
  }
  a:hover {
    color: var(--charcoal);
    background-color: var(--warm-sandstone);
  }
  p {
    margin: 0.5em;
    margin-bottom: 0.25em;
  }
  .logoutBtn{
    color: var(--warm-sandstone);
    background-color: var(--brick);
    transition: 0.1s;
    border-radius: 0 0 0.25em 0.25em;
    flex-grow: 1;
    &.router-link-exact-active {
      color: var(--charcoal);
      background-color: var(--warm-sandstone);
    }
  }
  .logoutBtn:hover {
    color: var(--charcoal);
    background-color: var(--warm-sandstone);
  }
}
body {
  height: var(--app-height);
  max-width: var(--app-width);
  font-size: 16px;
  width: 100vw;
  background-size: 100%;
  align-content: center;
  --brick: #BD482D; // Hobart Penitentiary
  --transparent-brick: #BD482DBB; // Tiled Buttons
  --warm-sandstone: #E6DCD2; // Text & Highlights
  --cool-sandstone: #E1E2DE; // Search Field
  --blue: #5E7FC9; // Forget-Me-Not
  --light-blue: #8499D0; // Forget-Me-Not
  --yellow: #F2D279; // Forget-Me-Not
  --white: #FFFFFF; // Search Field Highlights
  --light-grey: #A8A8A8; // Search Field Placeholder Text
  --grey: #808080; // Back Buttons
  --light-charcoal: #373737;
  --charcoal: #191919; // Background
  --transparent-charcoal: #191919BB; // Popup Background
  background-color: var(--charcoal);
}

// Fonts
@font-face {
  font-family: "DIN Condensed Bold";
  src: url(assets/fonts/din-condensed-bold.ttf) format("truetype");
}
h1 {
  font-family: "DIN Condensed Bold", sans-serif;
  font-size: clamp(2.5em, 5vw, 3.5em);
  line-height: 1em;
  margin: 0.5em;
}
h2 {
  font-family: "DIN Condensed Bold", sans-serif;
  font-size: clamp(1.5em, 5vw, 2.1em);
  line-height: 1em;
  margin: 0.5em;
}
h3, li {
  font-family: "DIN Condensed Bold", sans-serif;
  font-size: clamp(1.25em, 5vw, 1.75em);
  line-height: 1em;
  margin: 0.5em;
  margin-top: clamp(22px, 5vw, 25px);
}
p {
  font-family: "DIN Condensed Bold", sans-serif;
  font-size: clamp(1em, 5vw, 1.4em);
  line-height: 1em;
  margin: 0.5em;
}
a {
  text-decoration: none;
  color: var(--warm-sandstone);
}
mark {
  color: var(--brick);
  background-color: transparent;
}

// Icons
.icon {
  width: 2em;
  height: 2em;
  margin: 0.25em 0.5em;
  background-color: var(--warm-sandstone);
}
.search { mask: url(../src/assets/icons/search.svg) 0 0/2em 2em; }
.random { mask: url(../src/assets/icons/random.svg) 0 0/2em 2em; }
.back { mask: url(../src/assets/icons/back.svg) 0 0/2em 2em; }
.add-to-queue { mask: url(../src/assets/icons/add_to_queue.svg) 0 0/2em 2em; }
.confirm { mask: url(../src/assets/icons/confirm.svg) 0 0/2em 2em; }
.cancel { mask: url(../src/assets/icons/cancel.svg) 0 0/2em 2em; }
.forget-me-not { mask: url(../src/assets/icons/flower.svg) 0 0/2em 2em; }

.male { mask: url(/src/assets/faces/male_20.svg) 0 0/8em 8em; }
.male_10 { mask: url(/src/assets/faces/male_10.svg) 0 0/8em 8em; }
.male_15 { mask: url(/src/assets/faces/male_15.svg) 0 0/8em 8em; }
.male_20 { mask: url(/src/assets/faces/male_20.svg) 0 0/8em 8em; }
.male_30 { mask: url(/src/assets/faces/male_30.svg) 0 0/8em 8em; }
.male_40 { mask: url(/src/assets/faces/male_40.svg) 0 0/8em 8em; }

.female { mask: url(/src/assets/faces/female_20.svg) 0 0/8em 8em; }
.female_10 { mask: url(/src/assets/faces/female_10.svg) 0 0/8em 8em; }
.female_15 { mask: url(/src/assets/faces/female_15.svg) 0 0/8em 8em; }
.female_20 { mask: url(/src/assets/faces/female_20.svg) 0 0/8em 8em; }
.female_30 { mask: url(/src/assets/faces/female_30.svg) 0 0/8em 8em; }
.female_40 { mask: url(/src/assets/faces/female_40.svg) 0 0/8em 8em; }

.anvil { mask: url(../src/assets/faces/female_10.svg) 0 0/8em 8em; }
.anvilX { mask: url(../src/assets/icons/anvil.svg) 0 0/8em 8em; }
.bellows { mask: url(../src/assets/icons/bellows.svg) 0.25em -0.125em/8em 8em; }
.bricks { mask: url(../src/assets/icons/bricks.svg) 0 0/8em 8em; }
.building { mask: url(../src/assets/icons/building.svg) 0 0/8em 8em; }
.campfire { mask: url(../src/assets/icons/campfire.svg) 0 0/8em 8em; }
.cell { mask: url(../src/assets/icons/cell.svg) 0 0/8em 8em; }
.chains { mask: url(../src/assets/icons/chains.svg) 0 0/8em 8em; }
.gallows { mask: url(../src/assets/icons/gallows.svg) 0 0/8em 8em; }
.guns { mask: url(../src/assets/icons/guns.svg) 0 0/8em 8em; }
.horse { mask: url(../src/assets/icons/horse.svg) 0 0/8em 8em; }
.judge { mask: url(../src/assets/icons/judge.svg) 0 0/8em 8em; }
.notice { mask: url(../src/assets/icons/notice.svg) 0 0/8em 8em; }
.quill { mask: url(../src/assets/icons/quill.svg) 0 0/8em 8em; }
.records { mask: url(../src/assets/icons/records.svg) 0 0/8em 8em; }
.ship { mask: url(../src/assets/icons/ship.svg) 0 0/8em 8em; }
.tools { mask: url(../src/assets/icons/tools.svg) 0 0/8em 8em; }
.tree { mask: url(../src/assets/icons/tree.svg) 0 0/8em 8em; }
.wheel { mask: url(../src/assets/icons/wheel.svg) 0 0/8em 8em; }
.whip { mask: url(../src/assets/icons/whip.svg) 0 0/8em 8em; }
.wildlife { mask: url(../src/assets/icons/wildlife.svg) 0 0/8em 8em; }
</style>