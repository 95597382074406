<template>
  <div class="countdown-box" >
    <h2>YOUR CONVICT WILL PLAY AT</h2>
    {{ playingPanel }}
    <h1 class="countdown-timer">{{ startTime }}<mark>{{ampm(h)}}</mark></h1>
    <p>Your convict’s story will be told over four screens.<br>Be at the CONVICT MEMORIAL at {{ startTime }}{{ampm(h)}} to view them.</p>
  </div>

  <div class="countdown-box" v-if="playingPanel>0">
    <h1 class="countdown-timer">NOW PLAYING</h1>
    <h2>ON SCREEN 1: THE RECORDS</h2>
    <p>Wait for your convict's name to appear then follow it around the column when it moves!</p>
  </div>
</template>

<script>
export default {
  name: "EntryCountdown",
  props: {
    playingPanel:null

    
  },
  data() {
    return {
      d:null,
      t:null,
      h:null,
      a:null
    }
  },
  computed: {
    queueLength() {
      return this.$store.getters.getQueueLength
    },
    nextConIn() {
      return Math.max(0, this.$store.getters.getNextConIn)
    },
    panelTime() {
      return this.$store.getters.getPanelTime
    },
    conTimeSec(){
      return this.panelTime * (this.queueLength - 1) + this.nextConIn
    },
    conTime() {
      return this.timeText(this.conTimeSec)
    },
    startTime(){
      this.d = new Date();
      this.t=this.d.getHours()*60*60+this.d.getMinutes()*60+this.d.getSeconds()+this.conTimeSec
      this.h=Math.floor(this.t/60/60)
      return this.fixHours(this.h)+":"+this.twoDigit(Math.floor((this.t+30)/60)%60)
    }
  },
  methods: {
    suffix(val) {
      let last = val % 10
      if (last == 1) {
        return "st"
      } else if (last == 2) {
        return "nd"
      } else if (last == 3) {
        return "rd"
      }
      return "th"
    },
    timeText(time) {
      if (time <= 0) {
        return "00:00"
      }
      let min = Math.floor(time / 60)
      let sec = time - min * 60
      if (min < 10) {
        min = "0" + min
      }
      if (sec < 10) {
        sec = "0" + sec
      }
      return min + ":" + sec
    },
    fixHours(val){
      return val%12 ||12
    },
    ampm(h){
      if(h<12){
        return "AM"
      }else{
        return "PM"
      }
    },
    twoDigit(val) {
      if (val < 10) {
        return "0" + val
      } else {
        return val
      }
    },
  }
};

</script>

<style scoped>
.countdown-box {
  width: 100%;
  padding: 2em 15% 1.5em;
  margin-bottom: 3em;
  background-color: var(--brick);
  position: relative;
}
.countdown-box::after {
  content: "";
  position: absolute;
  width: 1000%;
  top: 0;
  bottom: 0;
  left: -500%;
  right: -500%;
  background-color: var(--brick);
  z-index: -9999;
}
.countdown-timer {
  font-size: clamp(3.5em, 12vw, 5em);
}
mark {
  font-size: 0.75em;
  color: var(--warm-sandstone);
}
h1, h2, p {
  margin: 0;
}
h2 {
  margin-bottom: 0.3em;
}
</style>