

export default {

  components:{},
    data() {
      return {
        
      }
    },
    mounted(){
      
    },
    computed:{
      convictInfo(){
        return this.convict
      },
      maleImages(){
        return this.$store.getters.getMaleImages
      },
      femaleImages(){
        return this.$store.getters.getFemaleImages
      },
      
      placeImages(){
        return this.$store.getters.getPlaceImages
      },
      tasmaniaImages(){
        return this.$store.getters.getTasmaniaImages
      },
      
      triedLocationImage(){
        let image="no Image"
        let country
        let location
        if(this.convictInfo){
          location=this.convictInfo.Trial_Place_tscript
          country=this.convictInfo.Trial_PlaceCountry_clean
          image=this.getPlaceImage(country,location,this.placeImages)
        }
        return image
      },
      tasLocationImage(){
        let image="no Image"
        let country
        let location
        if(this.convictInfo){
          location=null
          country="Tasmania"
          image=this.getPlaceImage(country,location,this.tasmaniaImages)
        }
        return image
      },
      placeImage(){
        let image="no Image"
        let country
        let location
        if(this.convictInfo){
          location=this.convictInfo.NativePlace 
          if(this.convictInfo.NP_Country){
            if(Array.isArray(this.convictInfo.NP_Country)){
              country=this.convictInfo.NP_Country[0]
            }else{
              country=this.convictInfo.NP_Country
            }
          }
          image=this.getPlaceImage(country,location,this.placeImages)
        }
        return image
      },
      convictImage(){
        if(this.convictInfo){
          
        let images
        let image="no Image"
       
        
        let age=this.convictInfo.AgeOnArrival
        if(this.gender=="Female"){
          if(this.race){
            if(this.race=="white"){
              images=this.femaleImages
            }else{
              images=this.femaleImagesRace(this.race)
            }
          }
          

        }else{
          images=this.maleImages
        }
        let imageAgeT=0
        let imageAge=20
        let imageArr=[]
        let possImages=[]
        let ageDiff=age-imageAge
        for(var i in images){
          imageArr=images[i].split("_")
          imageAgeT=parseInt(imageArr[1],10)
          if(Math.abs(age-imageAgeT)<ageDiff){
            imageAge=imageAgeT
            ageDiff=Math.abs(age-imageAgeT)
          }
        }
        for(var i in images){
          imageArr=images[i].split("_")
          imageAgeT=parseInt(imageArr[1],10)
          if(imageAgeT==imageAge){
            possImages.push(images[i])
          }
        }
        if(possImages.length==0){
          possImages=images.slice()
        }
        
        
        //checkCountry
        let npc
        if(this.convictInfo.NP_Country){
          if(Array.isArray(this.convictInfo.NP_Country)){
            npc=this.convictInfo.NP_Country[0]
          }else{
            npc=this.convictInfo.NP_Country
          }
          if(possImages.length>0){
            images=possImages.slice()
          }
          
          possImages=[]
          for(var i in images){
            if(images[i].indexOf(npc)>0){
          
              possImages.push(images[i])
            }else{
             
            }
          }
        }
        if(possImages.length>0){
          image=possImages[Math.floor(Math.random()*possImages.length)]
        }else if(images && images.length>0){
          image=images[Math.floor(Math.random()*images.length)]
        }
        return image
      }else{
        return null
      }
    }
    },
    methods: {
      femaleImagesRace(race){
        return this.$store.getters.getFemaleImagesRace(race)
      },
      
      
     
      getPlaceImage(country,location,imageSet){
        let image="No Convict"
        if(this.convictInfo){
          image="no Image"
          let images=[]
          let posImages=[]
          let imageT
          let countryPlace
          let locationPlace
        
        
          if(country || location){
            if(country){
              for(var i in imageSet){
                imageT=imageSet[i].split("_")
                if(imageT[0].toLowerCase()==country.toLowerCase()){
                  images.push(imageSet[i])
                }
              }
            }
            // check local
            if(images.length==0){
              console.log("No country Match for ",country)
              images=imageSet.slice()
            }else{
              countryPlace=country
            }
            if(location){
              let locs=location.split(" ").join("").split(",")
              for(var i in images){
                imageT=images[i].split("_")
                if(imageT.length>1 && locs.includes(imageT[1].split(" ").join(""))){
                  posImages.push(images[i])
                }
              }
            }
          if(posImages.length>0){
            locationPlace=location
            image=posImages[Math.floor(Math.random()*posImages.length)]
          }else if(images.length>0 && countryPlace){
            image=images[Math.floor(Math.random()*images.length)]
           } else{
            console.log("No Place",country,location)
           }
          }else{
            console.log("No Place",country)
          }
  
        }else{
  
        }
        return image
      },
      

    },
    watch:{
      
      
    }
  }
