<template>
  <div class="swipe">
    <div @click="prevItem" :class="'arrow arrow-left' + hasPrev"></div>
    <h2 @click="labelClick">{{ labelText }}</h2>
    <div @click="nextItem" disabled="true" :class="'arrow arrow-right' + hasNext"></div>
  </div>
</template>

<script>
export default {
  name: "EntrySwipe",
  props: {
    items: { type: Array },
    index: {
      default: 0,
    },
    labelText: {
      default: "SWIPE"
    },
  },
  data() {
    return {
      currentIndex: 0
    }
  },
  computed: {
    hasNext() {
      if (this.items && this.index < this.items.length - 1) {
        return ""
      } else {
        return " inactive"
      }
    },
    hasPrev() {
      if (this.index > 0) {
        return ""
      } else {
        return " inactive"
      }
    },
  },
  methods: {
    labelClick(){
      this.$emit('labelClick');
    },
    nextItem() {
      this.currentIndex = (this.currentIndex + 1) % this.items.length
    },
    prevItem() {
      this.currentIndex = (this.currentIndex - 1 + this.items.length) % this.items.length
    }
  },
  watch: {
    index(val) {
      this.currentIndex = val
    },
    currentIndex(val) {
      this.$emit('swipe', val);
    }
  }
};
</script>

<style lang="scss" scoped>
.convict-name {
  display: flex;
  justify-content: center;
  margin-top: 1.5em;
}
.swipe {
  display: flex;
  justify-content: center;
  .arrow {
    width: 0;
    height: 0;
    position: relative;
    border-top: 0.65em solid transparent;
    border-bottom: 0.65em solid transparent;
    margin: 0 2em;
    margin-top: 0.1em;
    transition: 0.1s;
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: -4em; bottom: -4em;
      left: -4em; right: -4em;
    }
  }
  .arrow-left {
    border-right: 1em solid var(--brick);
  }
  .arrow-right {
    border-left: 1em solid var(--brick);
  }
  .arrow-left:hover {
    border-right: 1em solid var(--warm-sandstone);
  }
  .arrow-right:hover {
    border-left: 1em solid var(--warm-sandstone);
  }
  h2 {
    margin: 0;
    text-transform: uppercase;
  }
  .inactive {
    opacity: 0.4;
    filter: grayscale(1);
    pointer-events: none;
  }
}
</style>