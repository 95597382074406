<template>
  <div class="large-circle">
    <div v-if="iconTrade" class="icon-circle" :class="iconTrade"></div>
    <div v-else-if="iconCrime" class="icon-circle" :class="iconCrime"></div>
    <div v-else></div>
    <div v-if="flag && flag!='fib fis' && flag.indexOf('undefined')==-1" class="flag-circle" :class="flag"></div>
    <div v-else></div>
  </div>
</template>

<script>
export default {
  name: "EntryCircle",
  props: {
    givenName: String,
    familyName: String,
    flag: String,
    iconTrade: String,
    iconCrime: String,
  },
};
</script>

<style scoped>
.large-circle {
  width: 8em;
  height: 8em;
  border-radius: 100%;
  position: relative;
  margin: auto;
  margin-top: -0.5em;
  background-color: var(--warm-sandstone);
}
.icon-circle {
    transform: scale(1.1);
    width: 8em;
    height: 8em;
    border-radius: 20%;
    position: absolute;
    bottom: 0%;
    right: 0%;
    background-color: var(--grey);
}
.flag-circle {
  width: 3em;
  height: 3em;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--light-charcoal);
  outline: 0.375em solid var(--charcoal);
}
</style>